import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProblemMetadata from "./components/ProblemMetadata";
import SubQuestions from "./components/SubQuestions";
import EditMathProblem from "./components/EditMathProblem";
import pdfMake from "pdfmake/build/pdfmake";
import PrintingLoader from "./components/PrintingLoader";
import { RadioGroup, RadioGroupItem } from "./components/ui/radio-group";
import "katex/dist/katex.min.css";

import jsPDF from "jspdf";

import "katex/dist/katex.min.css";
import katex from "katex";
import { toPng } from "html-to-image";

import { removeDiacritics } from "./lib/utils";
import { Tabs, TabsList, TabsTrigger } from "./components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from "./components/ui/dialog";
import pdfFonts from "pdfmake/build/vfs_fonts";
import AssignedTasks from "./components/AssignedTasks";
import MixedContent from "./components/MixedContent";

import TagManager from "./components/TagManager";
import TestEditor from "./components/TestEditor";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./components/ui/alert-dialog";
import { Card } from "./components/ui/card";
import { Input } from "./components/ui/input";
import LessonCalendar from "./components/LessonCalendar";
import ReactConfetti from "react-confetti";
import AssignTask from "./components/AssignTask";
import Fuse from "fuse.js";
import { Button } from "./components/ui/button";
import { Label } from "./components/ui/label";
import {
  CheckCircle,
  BarChart2,
  ClipboardList,
  Printer,
  XCircle,
  ChevronLeft,
  Loader2,
  Menu,
  Bookmark,
  User,
  PlusCircle,
  ChevronRight,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import Masonry from "react-masonry-css";
import { supabase } from "./components/supabaseClient";
import { Auth } from "./components/Auth";
import UserProgress from "./components/UserProgress";
import ImprovedFiltering from "./components/ImprovedFiltering";
import Statistics from "./components/Statistics";
import MathProblemCard from "./components/MathProblemCard";
import VideoRequest from "./components/VideoRequest";
import VideoRating from "./components/VideoRating";
import AddMathProblem from "./components/AddMathProblem";
import { toast, Toaster } from "react-hot-toast";
import UserProblemStatus from "./components/UserProblemStatus";
import TestManager from "./components/TestManager";
import TestSelector from "./components/TestSelector";
import TestView from "./components/TestView";
import AdminActionsDropdown from "./components/AdminActionsDropdown";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "./components/ui/sheet";

const VariantSkeleton = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
    className="mt-6"
  >
    <div className="flex-1 space-y-4 py-1"></div>
  </motion.div>
);

const App = () => {
  const [session, setSession] = useState(null);
  const customFonts = {
    Roboto: {
      normal:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
      bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
      italics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
      bolditalics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
    },
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.fonts = customFonts;

  const [loading, setLoading] = useState(true);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [savingProblems, setSavingProblems] = useState({});
  const [mathProblems, setMathProblems] = useState([]);
  const [filteredProblems, setFilteredProblems] = useState([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState("všetky");
  const [searchTerm, setSearchTerm] = useState("");
  const [userAnswer, setUserAnswer] = useState("");
  const [showHint, setShowHint] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showVariants, setShowVariants] = useState(false);
  const [showAssignTask, setShowAssignTask] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedProblem, setSelectedProblem] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [showTestManager, setShowTestManager] = useState(false);

  const [selectedProblems, setSelectedProblems] = useState([]);
  const [currentVariants, setCurrentVariants] = useState({
    main: null,
    variants: [],
    isLoading: false,
    isInitialized: false,
  });
  const [searchResults, setSearchResults] = useState([]);
  const [showAssignedTasks, setShowAssignedTasks] = useState(false);
  const [streak, setStreak] = useState(0);
  const [showAddProblem, setShowAddProblem] = useState(false);
  const [xp, setXp] = useState(0);
  const [showEditProblem, setShowEditProblem] = useState(false);
  const [problemToEdit, setProblemToEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [problemToDelete, setProblemToDelete] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [pdfHeadline, setPdfHeadline] = useState("");
  const [assignedTasksCount, setAssignedTasksCount] = useState(0);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const [savedProblems, setSavedProblems] = useState([]);
  const [showSavedOnly, setShowSavedOnly] = useState(false);
  const [sortBy, setSortBy] = useState("newest");
  const [wantLogIn, setWantLogIn] = useState(false);
  const [showUserProblemStatus, setShowUserProblemStatus] = useState(false);
  const [showLevelConfetti, setShowLevelConfetti] = useState(false);
  const [showProblemConfetti, setShowProblemConfetti] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isVariantsLoading, setIsVariantsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [showStatistics, setShowStatistics] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAssigning, setIsAssigning] = useState(false);
  const [assignmentStudent, setAssignmentStudent] = useState(null);
  const [assignmentDueDate, setAssignmentDueDate] = useState(null);
  const [isTagManagerOpen, setIsTagManagerOpen] = useState(false);
  const [hasLoadedUserProgress, setHasLoadedUserProgress] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [showMetadata, setShowMetadata] = useState(false);
  const [showTestEditor, setShowTestEditor] = useState(false);
  const [activeView, setActiveView] = useState("all"); // 'all' or 'test'
  const [selectedTest, setSelectedTest] = useState(null);
  const [previousFilterState, setPreviousFilterState] = useState(null);

  const filterProblemsByTags = useCallback((problem, allProblems) => {
    if (!problem || !problem.tags) return allProblems;
    return allProblems.filter(
      (p) => p.tags && p.tags.some((tag) => problem.tags.includes(tag))
    );
  }, []);

  const handleViewProblem = useCallback(
    (problemId) => {
      const problem = mathProblems.find((p) => p.id === problemId);
      if (problem) {
        setSelectedProblem(problem);
        setShowAssignedTasks(false);
        setShowStatistics(false);
        // Update filteredProblems based on the selected problem's tags
        const filtered = filterProblemsByTags(problem, mathProblems);
        setFilteredProblems(filtered);
        navigate(`/problem=${problemId}`);
      }
    },
    [mathProblems, filterProblemsByTags, navigate]
  );

  const [filterCriteria, setFilterCriteria] = useState({
    searchTerm: "",
    selectedDifficulty: "všetky",
    selectedTags: [],
    sortBy: "newest",
    sortOrder: "asc",
    showSavedOnly: false,
    includedTags: [], // Add this
    excludedTags: [], // Add this
  });

  const updateFilterCriteria = useCallback((newCriteria) => {
    console.log("Updating filter criteria:", newCriteria);
    setFilterCriteria((prev) => {
      const updated = {
        ...prev,
        ...newCriteria,
        selectedTags: Array.isArray(newCriteria.selectedTags)
          ? newCriteria.selectedTags
          : prev.selectedTags,
        includedTags: Array.isArray(newCriteria.includedTags)
          ? newCriteria.includedTags
          : prev.includedTags,
        excludedTags: Array.isArray(newCriteria.excludedTags)
          ? newCriteria.excludedTags
          : prev.excludedTags,
      };
      console.log("Updated filter criteria:", updated);
      return updated;
    });
  }, []);

  useEffect(() => {
    if (selectedProblem && selectedProblem.tags) {
      console.log("Selected problem tags:", selectedProblem.tags);
      const tagObjects = selectedProblem.tags
        .map((tagId) => allTags.find((tag) => tag.id === tagId))
        .filter((tag) => tag);

      updateFilterCriteria((prev) => ({
        ...prev,
        selectedTags: tagObjects,
      }));
    }
  }, [selectedProblem, allTags]);

  useEffect(() => {
    setFilteredProblems(searchResults);
  }, [searchResults]);

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.Hub.Config({
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          processEscapes: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const { data, error } = await supabase
        .from("tags")
        .select("*")

        .order("name");

      if (error) throw error;
      setAllTags(data);
    } catch (error) {
      console.error("Error fetching tags:", error);
      toast.error("Failed to fetch tags");
    }
  };

  const toggleTagManager = () => {
    setIsTagManagerOpen(!isTagManagerOpen);
  };

  const handleSelectAll = useCallback(
    (checked) => {
      setAllSelected(checked);
      if (checked) {
        setSelectedProblems(filteredProblems.map((problem) => problem.id));
      } else {
        setSelectedProblems([]);
      }
    },
    [filteredProblems]
  );

  const handleStudentAndDateSelected = (studentId, dueDate) => {
    setIsAssigning(true);
    setAssignmentStudent(studentId);
    setAssignmentDueDate(dueDate);
    setShowAssignTask(false);
  };

  const handleAssignProblems = async () => {
    if (selectedProblems.length === 0) {
      toast.error("Prosím, vyberte aspoň jeden príklad");
      return;
    }

    const assignments = selectedProblems.map((problemId) => ({
      student_id: assignmentStudent,
      problem_id: problemId,
      due_date: assignmentDueDate,
    }));

    const { data, error } = await supabase
      .from("assigned_problems")
      .insert(assignments);

    if (error) {
      console.error("Chyba pri priraďovaní úloh:", error);
      toast.error("Nepodarilo sa priradiť úlohy");
    } else {
      toast.success("Úlohy boli úspešne priradené");
      setIsAssigning(false);
      setAssignmentStudent(null);
      setAssignmentDueDate(null);
      setSelectedProblems([]);
    }
  };

  const isAdminUser = session?.user?.email === "admin@matikaodpatrika.sk";

  const fuse = useMemo(
    () =>
      new Fuse(mathProblems, {
        keys: ["question", "topic"],
        threshold: 0.3,
        ignoreLocation: true,
        normalizeWhitespace: true,
      }),
    [mathProblems]
  );

  const fetchMathProblems = async () => {
    try {
      setIsInitialLoading(true);
      const { data, error } = await supabase.from("math_problems").select(`
        *,
        math_problem_tags (
          tag_id
        ),
        sub_questions
      `);

      if (error) throw error;

      const problemsWithTags = data.map((problem) => ({
        ...problem,
        tags: problem.math_problem_tags.map((mpt) => mpt.tag_id),
        sub_questions: problem.sub_questions || [],
      }));

      setMathProblems(problemsWithTags);
      setFilteredProblems(problemsWithTags);

      // Check if there's a problem ID in the URL
      const pathname = location.pathname;
      const problemIdMatch = pathname.match(/\/problem=(\d+)/);
      if (problemIdMatch) {
        const problemId = problemIdMatch[1];
        const problem = problemsWithTags.find(
          (p) => p.id.toString() === problemId
        );
        if (problem) {
          setSelectedProblem(problem);
        }
      }
    } catch (error) {
      console.error("Error fetching math problems:", error);
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchMathProblems();
  }, []);

  const handleLogin = () => {
    setWantLogIn(true);
    navigate("/login");
  };

  const fetchAssignedTasksCount = async () => {
    if (!session) return;

    try {
      const { count, error } = await supabase
        .from("assigned_problems")
        .select("*", { count: "exact", head: true })
        .eq("student_id", session.user.id)
        .eq("status", "pending");

      if (error) throw error;
      setAssignedTasksCount(count || 0);
    } catch (error) {
      console.error("Error fetching assigned tasks count:", error);
    }
  };

  const handleProblemSelect = (problemId, isSelected) => {
    setShowAssignedTasks(false);
    setSelectedProblems((prev) =>
      isSelected ? [...prev, problemId] : prev.filter((id) => id !== problemId)
    );
  };
  const getImageSizeClass = (size) => {
    switch (size) {
      case "small":
        return "w-1/3";
      case "large":
        return "w-full";
      default:
        return "w-2/3";
    }
  };

  const getImageAlignmentClass = (alignment) => {
    switch (alignment) {
      case "left":
        return "float-left";
      case "right":
        return "float-right";
      default:
        return "mx-auto";
    }
  };

  const handlePrintPDF = useCallback(async () => {
    if (!pdfHeadline.trim()) {
      toast.error("Please enter a headline for the PDF");
      return;
    }

    setIsPrinting(true);

    try {
      const pdf = new jsPDF();
      let yOffset = 20;

      // Add headline to the first page only
      pdf.setFontSize(18);
      pdf.text(pdfHeadline, pdf.internal.pageSize.getWidth() / 2, yOffset, {
        align: "center",
      });
      yOffset += 20;

      for (let i = 0; i < selectedProblems.length; i++) {
        const problemId = selectedProblems[i];
        const problem = mathProblems.find((p) => p.id === problemId);

        if (!problem) continue;

        // Create a temporary div to render the problem
        const tempDiv = document.createElement("div");
        tempDiv.className = "bg-white p-3 rounded shadow";
        tempDiv.style.width = "600px";

        const problemTitle = document.createElement("h2");
        problemTitle.className = "text-base font-bold mb-2";
        problemTitle.textContent = `Príklad ${i + 1}`;
        tempDiv.appendChild(problemTitle);

        const problemContent = document.createElement("div");
        problemContent.className = "text-sm";
        problemContent.innerHTML = problem.question
          .split("$")
          .map((part, index) => {
            if (index % 2 === 0) {
              return part;
            } else {
              return katex.renderToString(part, { displayMode: false });
            }
          })
          .join("");
        tempDiv.appendChild(problemContent);

        // Add problem image if exists
        if (problem.image_url) {
          const imgContainer = document.createElement("div");
          imgContainer.className = "mt-2 mb-2";

          // Set image container width based on image_size
          switch (problem.image_size) {
            case "small":
              imgContainer.style.width = "33%";
              break;
            case "medium":
              imgContainer.style.width = "66%";
              break;
            case "large":
              imgContainer.style.width = "100%";
              break;
            default:
              imgContainer.style.width = "66%"; // Default to medium if not specified
          }

          // Set image alignment
          switch (problem.image_alignment) {
            case "left":
              imgContainer.style.float = "left";
              imgContainer.style.marginRight = "10px";
              break;
            case "right":
              imgContainer.style.float = "right";
              imgContainer.style.marginLeft = "10px";
              break;
            case "center":
            default:
              imgContainer.style.margin = "0 auto";
              break;
          }

          const img = document.createElement("img");
          img.src = problem.image_url;
          img.className = "max-w-full h-auto";
          imgContainer.appendChild(img);
          tempDiv.appendChild(imgContainer);

          // Clear float if not center aligned
          if (problem.image_alignment !== "center") {
            const clearDiv = document.createElement("div");
            clearDiv.style.clear = "both";
            tempDiv.appendChild(clearDiv);
          }
        }
        // Add subquestions if they exist
        if (problem.sub_questions && problem.sub_questions.length > 0) {
          const subQuestionsContainer = document.createElement("div");
          subQuestionsContainer.className = "mt-4";

          problem.sub_questions.forEach((subQ, index) => {
            const subQuestionDiv = document.createElement("div");
            subQuestionDiv.className = "mt-2";

            const subQuestionTitle = document.createElement("h3");
            subQuestionTitle.className = "text-sm font-semibold";
            subQuestionTitle.textContent = `${String.fromCharCode(
              97 + index
            )}) `;
            subQuestionDiv.appendChild(subQuestionTitle);

            const subQuestionContent = document.createElement("span");
            subQuestionContent.innerHTML = subQ.question
              .split("$")
              .map((part, index) => {
                if (index % 2 === 0) {
                  return part;
                } else {
                  return katex.renderToString(part, { displayMode: false });
                }
              })
              .join("");
            subQuestionDiv.appendChild(subQuestionContent);

            // Add options for subquestion if they exist
            if (subQ.options && Array.isArray(subQ.options)) {
              const optionsContainer = document.createElement("div");
              optionsContainer.className = "mt-2 ml-4";
              subQ.options.forEach((option, optionIndex) => {
                const optionElement = document.createElement("div");
                optionElement.className = "flex items-start space-x-1 mt-1";

                const optionLabel = document.createElement("span");
                optionLabel.className = "font-bold text-sm";
                optionLabel.textContent = `${String.fromCharCode(
                  65 + optionIndex
                )}. `;

                const optionContent = document.createElement("div");
                optionContent.className = "text-sm";
                optionContent.innerHTML = option
                  .split("$")
                  .map((part, idx) => {
                    if (idx % 2 === 0) {
                      return part;
                    } else {
                      return katex.renderToString(part, { displayMode: false });
                    }
                  })
                  .join("");

                optionElement.appendChild(optionLabel);
                optionElement.appendChild(optionContent);
                optionsContainer.appendChild(optionElement);
              });
              subQuestionDiv.appendChild(optionsContainer);
            }

            subQuestionsContainer.appendChild(subQuestionDiv);
          });

          tempDiv.appendChild(subQuestionsContainer);
        }

        // Add options if they exist
        if (problem.options && Array.isArray(problem.options)) {
          const optionsContainer = document.createElement("div");
          optionsContainer.className = "mt-2";
          problem.options.forEach((option, index) => {
            const optionElement = document.createElement("div");
            optionElement.className = "flex items-start space-x-1 mt-1";

            const optionLabel = document.createElement("span");
            optionLabel.className = "font-bold text-sm";
            optionLabel.textContent = `${String.fromCharCode(65 + index)}. `;

            const optionContent = document.createElement("div");
            optionContent.className = "text-sm";
            optionContent.innerHTML = option
              .split("$")
              .map((part, idx) => {
                if (idx % 2 === 0) {
                  return part;
                } else {
                  return katex.renderToString(part, { displayMode: false });
                }
              })
              .join("");

            optionElement.appendChild(optionLabel);
            optionElement.appendChild(optionContent);
            optionsContainer.appendChild(optionElement);
          });
          tempDiv.appendChild(optionsContainer);
        }

        document.body.appendChild(tempDiv);

        // Convert the div to an image
        const pngData = await toPng(tempDiv);

        // Remove the temporary div
        document.body.removeChild(tempDiv);

        // Add the image to the PDF
        const imgProps = pdf.getImageProperties(pngData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 40; // Margin of 20 on each side
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        if (yOffset + pdfHeight > pdf.internal.pageSize.getHeight() - 20) {
          pdf.addPage();
          yOffset = 20; // Reset yOffset for new page, but don't add headline
        }

        pdf.addImage(pngData, "PNG", 20, yOffset, pdfWidth, pdfHeight);
        yOffset += pdfHeight + 10;

        // Add a divider between problems
        if (i < selectedProblems.length - 1) {
          if (yOffset + 10 > pdf.internal.pageSize.getHeight() - 20) {
            pdf.addPage();
            yOffset = 20; // Reset yOffset for new page, but don't add headline
          } else {
            pdf.setDrawColor(200);
            pdf.line(
              20,
              yOffset,
              pdf.internal.pageSize.getWidth() - 20,
              yOffset
            );
            yOffset += 10;
          }
        }
      }

      pdf.save("matika_od_patrika_priklady.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Chyba pri generovaní PDF");
    } finally {
      setIsPrinting(false);
      setPdfHeadline(""); // Reset the headline after generating the PDF
    }
  }, [selectedProblems, mathProblems, pdfHeadline]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleSearch = useCallback(
    (term) => {
      if (!term.trim()) {
        setSearchResults([]);
        setFilteredProblems(mathProblems);
        return;
      }

      const normalizedTerm = removeDiacritics(term.toLowerCase());
      const words = normalizedTerm.split(/\s+/);

      const filtered = mathProblems.filter((problem) => {
        const normalizedQuestion = removeDiacritics(
          problem.question.toLowerCase()
        );
        return words.every((word) => normalizedQuestion.includes(word));
      });

      setSearchResults(filtered);
      setFilteredProblems(filtered);
    },
    [mathProblems]
  );

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, mathProblems]);

  const difficultyOptions = [
    { value: "všetky", label: "Všetky úrovne" },
    { value: "ľahké", label: "Ľahké" },
    { value: "stredné", label: "Stredné" },
    { value: "ťažké", label: "Ťažké" },
    { value: "nezaradené", label: "Nezaradené" },
  ];

  const handleToggleStatistics = () => {
    setShowStatistics(!showStatistics);
    setSelectedProblem(null);
    setIsMobileMenuOpen(false);
  };

  const handleEditProblem = (problem) => {
    setProblemToEdit(problem);
    setIsEditDialogOpen(true);
  };

  const handleDeleteProblem = (problemId) => {
    setProblemToDelete(problemId);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteProblem = async () => {
    if (problemToDelete) {
      try {
        // First, delete all variants of the problem
        const { data: variants, error: variantsError } = await supabase
          .from("math_problems")
          .delete()
          .eq("main_problem_id", problemToDelete);

        if (variantsError) throw variantsError;

        // Delete associated assigned problems
        const { error: assignedProblemsError } = await supabase
          .from("assigned_problems")
          .delete()
          .eq("problem_id", problemToDelete);

        if (assignedProblemsError) throw assignedProblemsError;

        // Delete associated video requests
        const { error: videoRequestsError } = await supabase
          .from("video_requests")
          .delete()
          .eq("problem_id", problemToDelete);

        if (videoRequestsError) throw videoRequestsError;

        // Delete associated video ratings
        const { error: videoRatingsError } = await supabase
          .from("video_ratings")
          .delete()
          .eq("problem_id", problemToDelete);

        if (videoRatingsError) throw videoRatingsError;

        // Finally, delete the main math problem
        const { error: mathProblemError } = await supabase
          .from("math_problems")
          .delete()
          .eq("id", problemToDelete);

        if (mathProblemError) throw mathProblemError;

        setMathProblems(mathProblems.filter((p) => p.id !== problemToDelete));
        setFilteredProblems(
          filteredProblems.filter((p) => p.id !== problemToDelete)
        );
        toast.success("Príklad a jeho varianty boli úspešne vymazané");
      } catch (error) {
        console.error("Error deleting problem:", error.message);
        toast.error("Chyba pri mazaní príkladu: " + error.message);
      } finally {
        setShowDeleteConfirmation(false);
        setProblemToDelete(null);
      }
    }
  };

  const handleProblemUpdated = (updatedProblem) => {
    setMathProblems(
      mathProblems.map((p) => (p.id === updatedProblem.id ? updatedProblem : p))
    );
    setFilteredProblems(
      filteredProblems.map((p) =>
        p.id === updatedProblem.id ? updatedProblem : p
      )
    );
    setShowEditProblem(false);
    setProblemToEdit(null);
  };

  const sortOptions = [
    { value: "newest", label: "Najnovšie" },
    { value: "difficulty", label: "Obtiažnosť" },
    { value: "topic", label: "Téma" },
  ];

  const getAdjacentProblemIds = useCallback(
    (currentProblemId) => {
      console.log("--- Debug getAdjacentProblemIds ---");
      console.log("Current Problem ID:", currentProblemId);

      // Get the current problem
      const currentProblem = mathProblems.find(
        (p) => p.id === currentProblemId
      );

      // Get selected tag IDs from filterCriteria or fall back to current problem's tags
      let selectedTagIds;
      if (filterCriteria.selectedTags.length > 0) {
        // If there are actively filtered tags, use those
        selectedTagIds = filterCriteria.selectedTags.map((tag) =>
          typeof tag === "object" ? tag.id : tag
        );
      } else if (currentProblem && currentProblem.tags) {
        // If no filtered tags but we have a current problem, use its tags
        selectedTagIds = currentProblem.tags;
      } else {
        // Fallback to empty array if neither is available
        selectedTagIds = [];
      }

      console.log("Selected Tag IDs:", selectedTagIds);

      // Filter problems based on selected tags
      const navigableProblems = mathProblems
        .filter((p) => {
          // Exclude variants
          if (p.main_problem_id) return false;

          // If no tags selected, include all problems
          if (selectedTagIds.length === 0) return true;

          // Check if problem has all selected tags
          return selectedTagIds.every(
            (tagId) => p.tags && p.tags.includes(tagId)
          );
        })
        .sort((a, b) => a.id - b.id);

      console.log(
        "Filtered problems:",
        navigableProblems.map((p) => ({ id: p.id, tags: p.tags }))
      );

      const currentIndex = navigableProblems.findIndex(
        (p) => p.id === (currentVariants?.main?.id || currentProblemId)
      );
      console.log("Current Index:", currentIndex);

      if (navigableProblems.length === 0) {
        console.log("No problems match the selected tags");
        return { prevProblemId: null, nextProblemId: null };
      }

      if (currentIndex === -1) {
        console.log(
          "Current problem not in filtered set, returning first available"
        );
        return {
          prevProblemId: navigableProblems[navigableProblems.length - 1].id,
          nextProblemId: navigableProblems[0].id,
        };
      }

      // Navigate within filtered problems
      const result = {
        prevProblemId:
          currentIndex > 0
            ? navigableProblems[currentIndex - 1].id
            : navigableProblems[navigableProblems.length - 1].id,
        nextProblemId:
          currentIndex < navigableProblems.length - 1
            ? navigableProblems[currentIndex + 1].id
            : navigableProblems[0].id,
      };

      console.log("Navigation Result:", result);
      return result;
    },
    [mathProblems, currentVariants, filterCriteria.selectedTags]
  );

  const handleBackToList = useCallback(() => {
    setSelectedProblem(null);
    setUserAnswer("");
    setShowHint(false);
    setFeedback(null);
    setCurrentVariants([]);
    setShowVariants(false);
    setSearchTerm("");

    // Restore previous filter state if it exists
    if (previousFilterState) {
      console.log("Restoring previous filter state:", previousFilterState); // Debug log
      updateFilterCriteria(previousFilterState);
    } else {
      updateFilterCriteria({ searchTerm: "" });
    }

    navigate("/");
  }, [updateFilterCriteria, previousFilterState]);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleDropdownClose = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  useEffect(() => {
    setAllSelected(false);
    setSelectedProblems([]);
  }, [filteredProblems]);
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("click", handleDropdownClose);
    } else {
      document.removeEventListener("click", handleDropdownClose);
    }
    return () => {
      document.removeEventListener("click", handleDropdownClose);
    };
  }, [isDropdownOpen, handleDropdownClose]);

  const handleFilter = useCallback(() => {
    let filtered = mathProblems;

    if (searchTerm) {
      const results = fuse.search(searchTerm);
      filtered = results.map((result) => result.item);
    }

    filtered = filtered.filter((problem) => {
      const difficultyMatch =
        selectedDifficulty === "všetky" ||
        problem.difficulty === selectedDifficulty;
      const savedMatch = !showSavedOnly || savedProblems.includes(problem.id);

      // Ensure problem.tags exists and is an array
      const problemTags = Array.isArray(problem.tags) ? problem.tags : [];

      const tagMatch =
        selectedTags.length === 0 ||
        selectedTags.every((selectedTag) => {
          // Ensure selectedTag is an object with a name property
          const selectedTagName =
            selectedTag && selectedTag.name
              ? selectedTag.name.toLowerCase()
              : "";

          return problemTags.some((problemTag) => {
            // Handle both string and object problemTags
            const problemTagName =
              typeof problemTag === "string"
                ? problemTag.toLowerCase()
                : problemTag && problemTag.name
                ? problemTag.name.toLowerCase()
                : "";

            return problemTagName === selectedTagName;
          });
        });

      return difficultyMatch && savedMatch && tagMatch;
    });

    if (sortBy !== "default") {
      filtered.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return sortOrder === "asc" ? -1 : 1;
        if (a[sortBy] > b[sortBy]) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });
    }

    setFilteredProblems(filtered);
  }, [
    fuse,
    searchTerm,
    selectedDifficulty,
    showSavedOnly,
    savedProblems,
    selectedTags,
    sortBy,
    sortOrder,
    mathProblems,
  ]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  const fetchUserProgress = useCallback(
    async (userId) => {
      if (hasLoadedUserProgress) return;
      setIsUserDataLoading(true);
      const { data, error } = await supabase
        .from("user_progress")
        .select("streak, xp, saved_problems")
        .eq("user_id", userId)
        .single();

      if (error) {
        console.error("Error fetching user progress:", error);
        if (error.code === "PGRST116") {
          await createUserProgress(userId);
        }
      } else if (data) {
        setStreak(data.streak);
        setXp(data.xp);
        setSavedProblems(data.saved_problems || []);
      } else {
        await createUserProgress(userId);
      }
      setHasLoadedUserProgress(true);
      setIsUserDataLoading(false);
    },
    [hasLoadedUserProgress]
  );

  useEffect(() => {
    if (session) {
      fetchUserProgress(session.user.id);
      fetchAssignedTasksCount();
    } else {
      setIsUserDataLoading(false);
    }
  }, [session, fetchUserProgress]);

  useEffect(() => {
    const fetchSession = async () => {
      setLoading(true);
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setSession(session);
      if (session && !hasLoadedUserProgress) {
        // Modify this line
        await fetchUserProgress(session.user.id);
      } else {
        setIsUserDataLoading(false);
      }
      setLoading(false);
    };

    fetchSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session && !hasLoadedUserProgress) {
        // Modify this line
        fetchUserProgress(session.user.id);
      } else {
        setIsUserDataLoading(false);
      }
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, [fetchUserProgress, hasLoadedUserProgress]);

  const createUserProgress = async (userId) => {
    const { data, error } = await supabase
      .from("user_progress")
      .insert({ user_id: userId, streak: 0, xp: 0, saved_problems: [] })
      .single();

    if (error) {
      console.error("Error creating user progress:", error);
    } else {
      setStreak(0);
      setXp(0);
      setSavedProblems([]);
    }
  };

  const updateUserProgress = async (
    userId,
    newStreak,
    newXp,
    newSavedProblems
  ) => {
    const { data, error } = await supabase
      .from("user_progress")
      .update({
        streak: newStreak,
        xp: newXp,
        saved_problems: newSavedProblems,
      })
      .eq("user_id", userId)
      .single();

    if (error) {
      console.error("Error updating user progress:", error);
    } else {
      setStreak(newStreak);
      setXp(newXp);
      setSavedProblems(newSavedProblems);
    }
  };

  const startChallenge = (problem) => {
    if (!problem) return;

    console.log("Starting challenge with problem:", problem);
    console.log("Current filter criteria:", filterCriteria);
    console.log("Current included tags:", filterCriteria.includedTags);

    setSelectedProblem(problem);
    setUserAnswer("");
    setUserAnswers([]);
    setShowHint(false);
    setFeedback(null);

    // Store the current filter state
    setPreviousFilterState({
      ...filterCriteria,
      includedTags: filterCriteria.includedTags || [],
      excludedTags: filterCriteria.excludedTags || [],
    });

    // Update filter criteria with the problem's tags
    if (problem.tags) {
      console.log("Problem tags:", problem.tags);
      const tagObjects = problem.tags
        .map((tagId) => allTags.find((tag) => tag.id === tagId))
        .filter(Boolean);

      updateFilterCriteria((prev) => {
        const newCriteria = {
          ...prev,
          selectedTags: tagObjects,
          includedTags: problem.tags,
          excludedTags: [],
        };
        console.log("Updated filter criteria:", newCriteria);
        return newCriteria;
      });
    }

    // Fetch variants if they exist
    if (problem.main_problem_id) {
      fetchVariants(problem.main_problem_id, problem.id);
    } else {
      fetchVariants(problem.id);
    }
  };

  useEffect(() => {
    const pathname = location.pathname;
    const problemIdMatch = pathname.match(/\/problem=(\d+)/);

    if (problemIdMatch) {
      const problemId = parseInt(problemIdMatch[1]);
      const problem = mathProblems.find((p) => p.id === problemId);
      if (problem) {
        setSelectedProblem(problem);
        setShowMetadata(false);
        // Fetch variants and set up related problems based on tags
        fetchVariants(problem.main_problem_id || problem.id, problem.id);

        // Filter problems by tags for navigation
        const filteredByTags = filterProblemsByTags(problem, mathProblems);
        setFilteredProblems(filteredByTags);
      }
    } else {
      setSelectedProblem(null);
    }
  }, [location, mathProblems]);

  const handleProblemClick = (problem) => {
    setSelectedProblem(problem);
    setShowMetadata(false);
  };

  const fetchVariants = async (mainProblemId, currentProblemId = null) => {
    setIsVariantsLoading(true);
    // Immediately reset current variants when fetching new ones
    setCurrentVariants((prev) => ({
      main: null,
      variants: [],
      isLoading: true,
      isInitialized: true,
    }));

    try {
      const { data, error } = await supabase
        .from("math_problems")
        .select(
          `
          *,
          math_problem_tags (
            tag_id
          )
        `
        )
        .or(`id.eq.${mainProblemId},main_problem_id.eq.${mainProblemId}`)
        .order("id");

      if (error) throw error;

      const transformedData = data.map((problem) => ({
        ...problem,
        tags: problem.math_problem_tags?.map((mpt) => mpt.tag_id) || [],
      }));

      const mainProblem =
        transformedData.find((p) => p.id === parseInt(mainProblemId)) || null;
      const variants = transformedData.filter(
        (p) => p.id !== parseInt(mainProblemId)
      );

      setCurrentVariants({
        main: mainProblem,
        variants,
        isLoading: false,
        isInitialized: true,
      });
    } catch (error) {
      console.error("Error fetching variants:", error);
      setCurrentVariants({
        main: null,
        variants: [],
        isLoading: false,
        isInitialized: true,
      });
    } finally {
      setIsVariantsLoading(false);
    }
  };

  const checkSolution = async () => {
    if (!selectedProblem) {
      console.error("No problem selected");
      return;
    }

    const normalizeAnswer = (answer) => {
      return answer.replace(",", ".").trim().toLowerCase();
    };

    let isCorrect = false;
    let feedbackMessage = "";

    if (selectedProblem.options) {
      // For multiple choice questions
      isCorrect = selectedOption === selectedProblem.answer[0];
      feedbackMessage = isCorrect
        ? "Výborne! Tvoja odpoveď je správna."
        : "Prepáč, to nie je správna odpoveď. Skús to znova.";
    } else if (
      selectedProblem.sub_questions &&
      selectedProblem.sub_questions.length > 0
    ) {
      // For multiple sub-questions
      isCorrect = selectedProblem.sub_questions.every((subQ, index) => {
        const userNormalizedAnswer = normalizeAnswer(userAnswers[index] || "");
        return Array.isArray(subQ.answer)
          ? subQ.answer.some(
              (correctAnswer) =>
                normalizeAnswer(correctAnswer) === userNormalizedAnswer
            )
          : normalizeAnswer(subQ.answer) === userNormalizedAnswer;
      });
      feedbackMessage = isCorrect
        ? "Brilantné! Všetky tvoje odpovede sú správne!"
        : "Takmer! Niektoré odpovede nie sú správne. Skús to ešte raz.";
    } else {
      // For single answer questions
      const userNormalizedAnswer = normalizeAnswer(userAnswer);
      isCorrect = selectedProblem.answer.some(
        (correctAnswer) =>
          normalizeAnswer(correctAnswer) === userNormalizedAnswer
      );
      feedbackMessage = isCorrect
        ? "Brilantné! Tvoje matematické schopnosti sú pozoruhodné!"
        : "Takmer! Skús to ešte raz a nezabúdaj na detaily.";
    }

    setFeedback({
      correct: isCorrect,
      message: feedbackMessage,
    });

    // Only update user progress if logged in
    if (session && isCorrect) {
      const newStreak = streak + 1;
      const newXp = xp + 20;

      // Update the assigned problem status
      const { error } = await supabase
        .from("assigned_problems")
        .update({ status: "completed" })
        .match({
          student_id: session.user.id,
          problem_id: selectedProblem.id,
        });

      if (error) {
      } else {
        // Refresh the assigned tasks count
        fetchAssignedTasksCount();
      }

      await updateUserProgress(
        session.user.id,
        newStreak,
        newXp,
        savedProblems
      );
      setStreak(newStreak);
      setXp(newXp);
      handleProblemComplete();
      if (Math.floor(newXp / 100) > Math.floor(xp / 100)) {
        handleLevelUp();
      }
    } else if (session && !isCorrect) {
      await updateUserProgress(session.user.id, 0, xp, savedProblems);
      setStreak(0);
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsMobileMenuOpen(false);
    setWantLogIn(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (
        selectedProblem.sub_questions &&
        selectedProblem.sub_questions.length > 0
      ) {
        // If there are subquestions, focus on the next input or check solution
        const inputs = document.querySelectorAll('input[type="text"]');
        const currentIndex = Array.from(inputs).indexOf(e.target);
        if (currentIndex < inputs.length - 1) {
          inputs[currentIndex + 1].focus();
        } else {
          checkSolution();
        }
      } else {
        checkSolution();
      }
    }
  };

  const handleLevelUp = useCallback(() => {
    if (!isInitialLoad) {
      setShowLevelConfetti(true);
      setTimeout(() => setShowLevelConfetti(false), 5000);
    }
  }, [isInitialLoad]);

  const handleProblemComplete = useCallback(() => {
    if (!isInitialLoad) {
      setShowProblemConfetti(true);
      setTimeout(() => setShowProblemConfetti(false), 3000);
    }
  }, [isInitialLoad]);

  const canSaveProblems = useCallback(() => {
    return !!session;
  }, [session]);

  const handleSaveProblem = useCallback(
    async (problemId) => {
      if (!canSaveProblems()) {
        toast.error("Pre uloženie príkladu sa musíte prihlásiť.", {
          duration: 3000,
          icon: "🔒",
        });
        return;
      }

      // Zistíme, či je aktuálny problém variantom
      const currentProblem = mathProblems.find((p) => p.id === problemId);
      const mainProblemId = currentProblem.main_problem_id || currentProblem.id;

      setSavingProblems((prev) => ({ ...prev, [mainProblemId]: true }));

      try {
        const newSavedProblems = savedProblems.includes(mainProblemId)
          ? savedProblems.filter((id) => id !== mainProblemId)
          : [...savedProblems, mainProblemId];

        await updateUserProgress(session.user.id, streak, xp, newSavedProblems);
        setSavedProblems(newSavedProblems);

        if (newSavedProblems.includes(mainProblemId)) {
          toast.success("Príklad bol úspešne uložený", {
            duration: 3000,
            icon: "🔖",
          });
        } else {
          toast.success("Príklad bol odstránený z uložených", {
            duration: 3000,
            icon: "🗑️",
          });
        }
      } catch (error) {
        console.error("Error saving problem:", error);
        toast.error("Chyba pri ukladaní príkladu", {
          duration: 3000,
          icon: "❌",
        });
      } finally {
        setSavingProblems((prev) => ({ ...prev, [mainProblemId]: false }));
      }
    },
    [
      session,
      savedProblems,
      streak,
      xp,
      updateUserProgress,
      canSaveProblems,
      mathProblems,
    ]
  );

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const handleProblemAdded = useCallback(
    (newProblem) => {
      setMathProblems((prevProblems) => [newProblem, ...prevProblems]);
      setFilteredProblems((prevFiltered) => [newProblem, ...prevFiltered]);
      fetchMathProblems(); // Refresh the problem list
    },
    [fetchMathProblems]
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (wantLogIn && !session) {
    return (
      <div
        className="flex justify-center items-center h-screen bg-gray-50"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='220' height='220' viewBox='0 0 220 220'%3E%3Cpath d='M0 20h220M0 40h220M0 60h220M0 80h220M0 100h220M0 120h220M0 140h220M0 160h220M0 180h220M0 200h220M20 0v220M40 0v220M60 0v220M80 0v220M100 0v220M120 0v220M140 0v220M160 0v220M180 0v220M200 0v220' stroke='%23E6E6E6' stroke-width='0.5' opacity='0.4'/%3E%3Cpath d='M0 0h220M0 220h220M0 0v220M220 0v220' stroke='%23D9D9D9' stroke-width='0.75' opacity='0.5'/%3E%3C/svg%3E")`,
          backgroundSize: "100px 100px",
        }}
      >
        <Auth />
      </div>
    );
  }

  return (
    <div
      className="min-h-screen bg-white p-4 md:p-8 md:pt-4"
      style={{
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='220' height='220' viewBox='0 0 220 220'%3E%3Cpath d='M0 20h220M0 40h220M0 60h220M0 80h220M0 100h220M0 120h220M0 140h220M0 160h220M0 180h220M0 200h220M20 0v220M40 0v220M60 0v220M80 0v220M100 0v220M120 0v220M140 0v220M160 0v220M180 0v220M200 0v220' stroke='%23E6E6E6' stroke-width='0.5' opacity='0.4'/%3E%3Cpath d='M0 0h220M0 220h220M0 0v220M220 0v220' stroke='%23D9D9D9' stroke-width='0.75' opacity='0.5'/%3E%3C/svg%3E")`,
        backgroundSize: "100px 100px",
      }}
    >
      <Toaster position="top-center" />
      {!isInitialLoad && showLevelConfetti && (
        <ReactConfetti
          recycle={false}
          numberOfPieces={200}
          tweenDuration={5000}
        />
      )}
      {!isInitialLoad && showProblemConfetti && (
        <ReactConfetti
          recycle={false}
          numberOfPieces={50}
          tweenDuration={3000}
          gravity={0.2}
        />
      )}

      {isAdminUser && selectedProblems.length > 0 && !isAssigning && (
        <div className="fixed bottom-4 right-4 z-50 flex space-x-4">
          <Button
            onClick={() => setIsPrinting(true)}
            disabled={isPrinting}
            className="whitespace-nowrap"
          >
            <Printer className="mr-2 h-4 w-4" />
            {isPrinting ? "Generujem PDF..." : "Vytlačiť Vybrané Príklady"}
          </Button>
          <Button
            onClick={() => setShowTestManager(true)}
            className="whitespace-nowrap"
          >
            <PlusCircle className="mr-2 h-4 w-4" />
            Pridať do Testu
          </Button>
        </div>
      )}

      <nav className="mb-8">
        <div className="flex justify-between items-center">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              setSelectedProblem(null);
              setShowStatistics(false);
              navigate("/"); // This will change the URL to the root path
            }}
          >
            <img
              src="/matpat.png"
              alt="Matika od Patrika Logo"
              className="max-w-full h-auto w-20 md:w-20"
            />
          </div>

          <div className="flex items-center space-x-4">
            {session && !isUserDataLoading && (
              <UserProgress streak={streak} xp={xp} onLevelUp={handleLevelUp} />
            )}
            {session && (
              <Button
                variant="ghost"
                size="sm"
                onClick={handleToggleStatistics}
                className="hidden md:flex items-center"
              >
                <BarChart2 className="h-5 w-5 mr-1" />
                <span>Štatistiky</span>
              </Button>
            )}

            {isAdminUser && (
              <AdminActionsDropdown
                onShowStatistics={handleToggleStatistics}
                onAddProblem={() => setShowAddProblem(true)}
                onManageTags={toggleTagManager}
                onShowCalendar={() => navigate("/lekcie")}
                onManageTests={() => setShowTestEditor(true)}
                onAssignTask={() => setShowAssignTask(true)}
                onShowUserStatus={() => setShowUserProblemStatus(true)}
              />
            )}

            <div className="flex items-center justify-between">
              {!selectedProblem && !showStatistics && (
                <div className="flex-1 max-w-2xl">
                  <Tabs defaultValue="all" className="w-full">
                    <TabsList className="grid w-full grid-cols-2">
                      <TabsTrigger
                        value="all"
                        onClick={() => {
                          setActiveView("all");
                          setSelectedTest(null);
                        }}
                      >
                        Všetky príklady
                      </TabsTrigger>
                      <TabsTrigger
                        value="test"
                        onClick={() => {
                          setActiveView("test");
                          setSelectedTest(null);
                        }}
                      >
                        Testy
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>
                </div>
              )}

              {session ? (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleLogout}
                  className="hidden md:flex ml-4 items-center"
                >
                  <User className="h-5 w-5 mr-1" />
                  <span>Odhlásiť sa</span>
                </Button>
              ) : (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleLogin}
                  className="hidden md:flex ml-4 items-center"
                >
                  <User className="h-5 w-5 mr-1" />
                  <span>Prihlásiť sa</span>
                </Button>
              )}
            </div>

            <Button
              variant="ghost"
              size="sm"
              onClick={toggleMobileMenu}
              className="md:hidden"
            >
              <Menu className="h-5 w-5" />
            </Button>
          </div>
        </div>
      </nav>

      <Dialog open={isTagManagerOpen} onOpenChange={setIsTagManagerOpen}>
        <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Správa tagov</DialogTitle>
          </DialogHeader>
          <TagManager onClose={() => setIsTagManagerOpen(false)} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={showUserProblemStatus}
        onOpenChange={setShowUserProblemStatus}
      >
        <DialogContent className="max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
          <DialogHeader>
            <DialogTitle>Stav úloh používateľov</DialogTitle>
          </DialogHeader>
          <div className="flex-grow overflow-hidden">
            <UserProblemStatus />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={showAssignedTasks} onOpenChange={setShowAssignedTasks}>
        <DialogContent className="max-w-4xl max-h-[90vh] overflow-auto">
          <DialogHeader>
            <DialogTitle>Vaše zadané úlohy</DialogTitle>
            <DialogDescription>
              Tu nájdete zoznam všetkých vašich aktuálnych úloh.
            </DialogDescription>
          </DialogHeader>
          <AssignedTasks
            userId={session?.user?.id}
            onViewProblem={handleViewProblem}
            onTaskCompleted={() =>
              setAssignedTasksCount((prev) => Math.max(prev - 1, 0))
            }
            onClose={() => setShowAssignedTasks(false)}
          />
        </DialogContent>
      </Dialog>

      {/* Mobile Menu */}
      <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
        <SheetContent side="right">
          <SheetHeader>
            <SheetTitle>Menu</SheetTitle>
          </SheetHeader>
          <div className="py-4">
            {session && (
              <Button
                variant="ghost"
                className="w-full justify-start mb-2"
                onClick={() => {
                  handleToggleStatistics();
                  setIsMobileMenuOpen(false);
                }}
              >
                <BarChart2 className="mr-2 h-4 w-4" />
                {showStatistics ? "Príklady" : "Štatistiky"}
              </Button>
            )}

            {session && !isAdminUser && (
              <Button
                variant="ghost"
                className="w-full justify-start mb-2"
                onClick={() => {
                  setShowAssignedTasks(true);
                  setIsMobileMenuOpen(false);
                }}
              >
                <ClipboardList className="mr-2 h-4 w-4" />
                Moje úlohy
                {assignedTasksCount > 0 && (
                  <span className="ml-2 bg-red-100 text-red-800 text-xs font-semibold px-2 py-0.5 rounded-full">
                    {assignedTasksCount}
                  </span>
                )}
              </Button>
            )}

            {session ? (
              <Button
                variant="ghost"
                className="w-full justify-start"
                onClick={() => {
                  handleLogout();
                  setIsMobileMenuOpen(false);
                }}
              >
                <User className="mr-2 h-4 w-4" />
                Odhlásiť sa
              </Button>
            ) : (
              <Button
                variant="ghost"
                className="w-full justify-start"
                onClick={() => {
                  handleLogin();
                  setIsMobileMenuOpen(false);
                }}
              >
                <User className="mr-2 h-4 w-4" />
                Prihlásiť sa
              </Button>
            )}
          </div>
        </SheetContent>
      </Sheet>

      {/* Main Content */}

      {showAssignTask && (
        <Dialog open={showAssignTask} onOpenChange={setShowAssignTask}>
          <DialogContent>
            <AssignTask
              onClose={() => setShowAssignTask(false)}
              onStudentAndDateSelected={handleStudentAndDateSelected}
            />
          </DialogContent>
        </Dialog>
      )}

      {showStatistics && session ? (
        <div className="max-w-4xl mx-auto">
          <Button
            onClick={handleToggleStatistics}
            className="mb-4 flex items-center"
            variant="outline"
          >
            <ChevronLeft className="mr-2" /> Späť na príklady
          </Button>
          <Statistics userId={session?.user?.id} />
        </div>
      ) : (
        <>
          {activeView === "test" && !selectedProblem && (
            <>
              {selectedTest ? (
                <TestView
                  test={selectedTest}
                  onBack={() => setSelectedTest(null)}
                  allTags={allTags}
                />
              ) : (
                <TestSelector onTestSelect={setSelectedTest} />
              )}
            </>
          )}

          {/* start of main part including filters */}
          {activeView !== "test" && !selectedProblem && (
            <>
              {!selectedProblem && (
                <div className="mb-14 max-w-2xl mx-auto">
                  <ImprovedFiltering
                    allSelected={allSelected}
                    isAdminUser={isAdminUser}
                    setSearchResults={setSearchResults}
                    onSelectAll={handleSelectAll}
                    onProblemClick={handleProblemClick}
                    searchTerm={filterCriteria.searchTerm}
                    setSearchTerm={(term) => {
                      setSearchTerm(term);
                      updateFilterCriteria({ searchTerm: term });
                    }}
                    selectedDifficulty={filterCriteria.selectedDifficulty}
                    setSelectedDifficulty={(difficulty) =>
                      updateFilterCriteria({ selectedDifficulty: difficulty })
                    }
                    selectedTags={filterCriteria.selectedTags}
                    setSelectedTags={(tags) =>
                      updateFilterCriteria({ selectedTags: tags })
                    }
                    sortBy={filterCriteria.sortBy}
                    setSortBy={(sort) => updateFilterCriteria({ sortBy: sort })}
                    sortOrder={filterCriteria.sortOrder}
                    toggleSortOrder={() =>
                      updateFilterCriteria({
                        sortOrder:
                          filterCriteria.sortOrder === "asc" ? "desc" : "asc",
                      })
                    }
                    showSavedOnly={filterCriteria.showSavedOnly}
                    setShowSavedOnly={(show) =>
                      updateFilterCriteria({ showSavedOnly: show })
                    }
                    difficultyOptions={difficultyOptions}
                    allTags={allTags}
                    sortOptions={sortOptions}
                    canSaveProblems={canSaveProblems()}
                    onSearch={handleSearch}
                    searchResults={searchResults}
                    onProblemSelect={handleProblemSelect}
                    setFilteredProblems={setFilteredProblems}
                    mathProblems={mathProblems}
                    savedProblems={savedProblems}
                    updateFilterCriteria={updateFilterCriteria}
                  />
                </div>
              )}
              {isAssigning && (
                <Button
                  className="fixed bottom-4 right-4 z-50"
                  onClick={handleAssignProblems}
                >
                  Priradiť študentovi
                </Button>
              )}
            </>
          )}
          {/* end of main part including filters */}

          {selectedProblem ? (
            <>
              <div className="flex flex-col space-y-4 max-w-6xl mb-4">
                {/* Back button */}
                <Button
                  onClick={handleBackToList}
                  variant="outline"
                  className="self-start flex items-center gap-2 px-4 py-2"
                >
                  <ChevronLeft className="h-5 w-5" />
                  <span>Späť</span>
                </Button>

                {/* Mobile and Tablet navigation buttons */}
                <div className="flex justify-between w-full lg:hidden mt-4">
                  <div className="flex-1">
                    <Button
                      onClick={() => {
                        const { prevProblemId } = getAdjacentProblemIds(
                          selectedProblem.id
                        );
                        if (prevProblemId) {
                          navigate(`/problem=${prevProblemId}`);
                        }
                      }}
                      variant="outline"
                      className="w-full flex items-center justify-center gap-2 px-4 py-2"
                    >
                      <ChevronLeft className="h-5 w-5" />
                      <span>Predchádzajúci</span>
                    </Button>
                  </div>

                  <div className="w-4"></div>

                  <div className="flex-1">
                    <Button
                      onClick={() => {
                        const { nextProblemId } = getAdjacentProblemIds(
                          selectedProblem.id
                        );
                        if (nextProblemId) {
                          navigate(`/problem=${nextProblemId}`);
                        }
                      }}
                      variant="outline"
                      className="w-full flex items-center justify-center gap-2 px-4 py-2"
                    >
                      <span>Ďalší</span>
                      <ChevronRight className="h-5 w-5" />
                    </Button>
                  </div>
                </div>

                {/* Desktop navigation buttons */}
                <div className="hidden lg:block fixed left-8 top-1/2 transform -translate-y-1/2">
                  <Button
                    onClick={() => {
                      const { prevProblemId } = getAdjacentProblemIds(
                        selectedProblem.id
                      );
                      if (prevProblemId) {
                        navigate(`/problem=${prevProblemId}`);
                      }
                    }}
                    variant="outline"
                    className="rounded-full w-16 h-16 flex items-center justify-center bg-white shadow-sm hover:bg-gray-100 transition-colors duration-200"
                    aria-label="Previous problem"
                  >
                    <ChevronLeft className="h-8 w-8" />
                  </Button>
                </div>

                <div className="hidden lg:block fixed right-8 top-1/2 transform -translate-y-1/2">
                  <Button
                    onClick={() => {
                      const { nextProblemId } = getAdjacentProblemIds(
                        selectedProblem.id
                      );
                      if (nextProblemId) {
                        navigate(`/problem=${nextProblemId}`);
                      } else {
                        toast.info("No more problems matching current filters");
                      }
                    }}
                    variant="outline"
                    className="rounded-full w-16 h-16 flex items-center justify-center bg-white shadow-sm hover:bg-gray-100 transition-colors duration-200"
                    aria-label="Next problem"
                    disabled={
                      !getAdjacentProblemIds(selectedProblem.id).nextProblemId
                    }
                  >
                    <ChevronRight className="h-8 w-8" />
                  </Button>
                </div>
              </div>

              <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4 max-w-6xl mx-auto">
                <div className="flex justify-center min-w-full flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
                  <div className="w-full order-1 lg:order-2 lg:w-3/3">
                    <div className="relative w-full max-w-4xl mx-auto">
                      <Card className="p-6 relative">
                        <ProblemMetadata
                          tagIds={
                            selectedProblem.main_problem_id
                              ? currentVariants.main?.tags || []
                              : selectedProblem.tags
                          }
                          allTags={allTags}
                          difficulty={
                            selectedProblem.main_problem_id
                              ? currentVariants.main?.difficulty ||
                                selectedProblem.difficulty
                              : selectedProblem.difficulty
                          }
                          isVisible={showMetadata}
                          onToggle={() => setShowMetadata(!showMetadata)}
                        />

                        <h2 className="text-xl pr-8 leading-loose text-gray-800 mb-4">
                          <MixedContent content={selectedProblem.question} />
                        </h2>

                        {selectedProblem.image_url && (
                          <div className="mb-4 w-full overflow-hidden">
                            <div
                              className={`${getImageSizeClass(
                                selectedProblem.image_size
                              )} ${getImageAlignmentClass(
                                selectedProblem.image_alignment
                              )}`}
                            >
                              <img
                                src={selectedProblem.image_url}
                                alt="Problem illustration"
                                className="w-full h-auto rounded-lg"
                              />
                            </div>
                          </div>
                        )}
                        {selectedProblem.image && (
                          <div className="mb-4">
                            <img
                              src={selectedProblem.image}
                              alt="Problem illustration"
                              className="w-full h-auto rounded-lg"
                            />
                          </div>
                        )}
                        <div className="mb-4">
                          {selectedProblem.video_tutorial_url ? (
                            <div>
                              <h3 className="text-lg font-semibold mb-2">
                                Video tutoriál
                              </h3>
                              <div
                                className="relative w-full"
                                style={{ paddingTop: "56.25%" }}
                              >
                                {" "}
                                {/* 16:9 Aspect Ratio */}
                                <iframe
                                  className="absolute top-0 left-0 w-full h-full"
                                  src={selectedProblem.video_tutorial_url}
                                  title="Video tutoriál"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              </div>
                              <div className="mt-2">
                                <VideoRating problemId={selectedProblem.id} />
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <Input
                          type="text"
                          placeholder="Zadaj svoje riešenie"
                          value={userAnswer}
                          onChange={(e) => setUserAnswer(e.target.value)}
                          onKeyPress={handleKeyPress}
                          className="w-full mb-4"
                        />

                        {selectedProblem.options && (
                          <Card className="mt-4 p-4">
                            <RadioGroup
                              value={selectedOption}
                              onValueChange={setSelectedOption}
                              className="space-y-2"
                            >
                              {selectedProblem.options.map((option, index) => (
                                <div
                                  key={index}
                                  className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 transition-colors"
                                >
                                  <RadioGroupItem
                                    value={option}
                                    id={`option-${index}`}
                                    className="w-4 h-4"
                                  />
                                  <Label
                                    htmlFor={`option-${index}`}
                                    className="flex-grow cursor-pointer text-lg"
                                  >
                                    <MixedContent content={option} />
                                  </Label>
                                </div>
                              ))}
                            </RadioGroup>
                          </Card>
                        )}

                        {selectedProblem.sub_questions &&
                          selectedProblem.sub_questions.length > 0 && (
                            <SubQuestions
                              subQuestions={selectedProblem.sub_questions}
                              userAnswers={userAnswers}
                              setUserAnswers={setUserAnswers}
                              onEnterPress={checkSolution}
                            />
                          )}

                        <div className="space-y-4 mt-4 mb-4">
                          <div className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center space-y-2 sm:space-y-0">
                            <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
                              <Button
                                onClick={checkSolution}
                                className="w-full sm:w-auto"
                              >
                                Overiť riešenie
                              </Button>
                              {selectedProblem.hint &&
                                selectedProblem.hint.trim() !== "" && (
                                  <Button
                                    onClick={() => setShowHint(!showHint)}
                                    variant="outline"
                                    className="w-full sm:w-auto"
                                  >
                                    {showHint
                                      ? "Skryť nápovedu"
                                      : "Zobraziť nápovedu"}
                                  </Button>
                                )}
                            </div>
                            <div className="flex items-center space-x-2">
                              <VideoRequest problemId={selectedProblem.id} />
                              <Button
                                onClick={() =>
                                  handleSaveProblem(selectedProblem.id)
                                }
                                disabled={
                                  savingProblems[
                                    selectedProblem.main_problem_id ||
                                      selectedProblem.id
                                  ]
                                }
                                className="p-2 h-10 w-10 flex items-center justify-center"
                                variant="outline"
                                title={
                                  savedProblems.includes(
                                    selectedProblem.main_problem_id ||
                                      selectedProblem.id
                                  )
                                    ? "Odstránit z uložených"
                                    : "Uložit príklad"
                                }
                              >
                                {savingProblems[
                                  selectedProblem.main_problem_id ||
                                    selectedProblem.id
                                ] ? (
                                  <Loader2 className="h-5 w-5 animate-spin" />
                                ) : savedProblems.includes(
                                    selectedProblem.main_problem_id ||
                                      selectedProblem.id
                                  ) ? (
                                  <Bookmark className="h-5 w-5 fill-current" />
                                ) : (
                                  <Bookmark className="h-5 w-5" />
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>

                        <AnimatePresence>
                          {feedback && (
                            <motion.div
                              initial={{ opacity: 0, scale: 0.9 }}
                              animate={{ opacity: 1, scale: 1 }}
                              exit={{ opacity: 0, scale: 0.9 }}
                              className={`flex items-center p-4 mb-4 rounded ${
                                feedback.correct
                                  ? "bg-green-100 text-green-800"
                                  : "bg-red-100 text-red-800"
                              }`}
                            >
                              {feedback.correct ? (
                                <CheckCircle className="mr-2" />
                              ) : (
                                <XCircle className="mr-2" />
                              )}
                              {feedback.message}
                            </motion.div>
                          )}
                        </AnimatePresence>
                        <AnimatePresence>
                          {/* First check if we have variants to show */}
                          {currentVariants.isInitialized &&
                            !currentVariants.isLoading &&
                            (currentVariants.variants?.length > 0 ||
                              (currentVariants.main &&
                                currentVariants.main.id !==
                                  selectedProblem?.id)) && (
                              <AnimatePresence>
                                <motion.div
                                  key="variants-section"
                                  initial={{ opacity: 0, height: 0 }}
                                  animate={{ opacity: 1, height: "auto" }}
                                  exit={{ opacity: 0, height: 0 }}
                                  transition={{
                                    duration: 0.3,
                                    ease: "easeInOut",
                                  }}
                                  className="mt-6 bg-gray-50 border border-gray-200 rounded-lg mb-4 p-4 overflow-hidden"
                                >
                                  <div className="space-y-4">
                                    <h3 className="text-lg font-semibold">
                                      Obdobné príklady:
                                    </h3>
                                    <div className="flex flex-wrap gap-2">
                                      {isVariantsLoading ? (
                                        <>
                                          <VariantSkeleton />
                                          <VariantSkeleton />
                                          <VariantSkeleton />
                                        </>
                                      ) : (
                                        <>
                                          {currentVariants.main &&
                                            selectedProblem &&
                                            currentVariants.main.id !==
                                              selectedProblem.id && (
                                              <Button
                                                onClick={() =>
                                                  startChallenge(
                                                    currentVariants.main
                                                  )
                                                }
                                                variant="outline"
                                                size="sm"
                                                className="bg-white hover:bg-indigo-50 text-indigo-600 hover:text-indigo-700 border-indigo-200 hover:border-indigo-300 transition-colors"
                                              >
                                                Základný príklad
                                              </Button>
                                            )}
                                          {currentVariants.variants?.map(
                                            (variant, index) => (
                                              <Button
                                                key={variant.id}
                                                onClick={() =>
                                                  startChallenge(variant)
                                                }
                                                variant="outline"
                                                size="sm"
                                                className={`bg-white hover:bg-indigo-50 text-indigo-600 hover:text-indigo-700 border-indigo-200 hover:border-indigo-300 transition-colors ${
                                                  selectedProblem?.id ===
                                                  variant.id
                                                    ? "bg-indigo-100"
                                                    : ""
                                                }`}
                                                disabled={
                                                  selectedProblem?.id ===
                                                  variant.id
                                                }
                                              >
                                                Variant{" "}
                                                {String.fromCharCode(
                                                  65 + index
                                                )}
                                              </Button>
                                            )
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </motion.div>
                              </AnimatePresence>
                            )}
                        </AnimatePresence>

                        <AnimatePresence>
                          {showHint && (
                            <motion.div
                              initial={{ opacity: 0, y: -10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -10 }}
                              className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4"
                            >
                              <h3 className="font-bold text-yellow-800 mb-2 ">
                                Nápoveda:
                              </h3>
                              <p className="text-yellow-700">
                                {selectedProblem.hint}
                              </p>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            activeView !== "test" && (
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {filteredProblems.length > 0
                  ? filteredProblems.map((problem) => (
                      <MathProblemCard
                        key={`problem-${problem.id}`}
                        problem={problem}
                        onSave={handleSaveProblem}
                        isSaved={savedProblems.includes(problem.id)}
                        onClick={() => startChallenge(problem)}
                        canSave={canSaveProblems()}
                        isAdminUser={isAdminUser}
                        onEdit={handleEditProblem}
                        onDelete={handleDeleteProblem}
                        isSelected={
                          selectedProblems.includes(problem.id) || allSelected
                        }
                        onSelect={handleProblemSelect}
                        allTags={allTags}
                        isSaving={savingProblems[problem.id]}
                      />
                    ))
                  : mathProblems.map((problem) => (
                      <MathProblemCard
                        key={`problem-${problem.id}`}
                        problem={problem}
                        onSave={handleSaveProblem}
                        isSaved={savedProblems.includes(problem.id)}
                        onClick={() => startChallenge(problem)}
                        canSave={canSaveProblems()}
                        isAdminUser={isAdminUser}
                        onEdit={handleEditProblem}
                        onDelete={handleDeleteProblem}
                        isSelected={
                          selectedProblems.includes(problem.id) || allSelected
                        }
                        onSelect={handleProblemSelect}
                        allTags={allTags}
                      />
                    ))}
              </Masonry>
            )
          )}

          {showTestEditor && (
            <TestEditor
              isOpen={showTestEditor}
              onClose={() => setShowTestEditor(false)}
            />
          )}

          {isEditDialogOpen && problemToEdit && (
            <EditMathProblem
              problem={problemToEdit}
              onClose={() => setIsEditDialogOpen(false)}
              onProblemUpdated={handleProblemUpdated}
              session={session}
              open={isEditDialogOpen}
            />
          )}

          <AlertDialog
            open={showDeleteConfirmation}
            onOpenChange={setShowDeleteConfirmation}
          >
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Ste si istý, že chcete vymazať tento príklad?
                </AlertDialogTitle>
                <AlertDialogDescription>
                  Táto akcia sa nedá vrátiť späť. Príklad bude natrvalo
                  odstránený z databázy. Všetky súvisiace žiadosti o video a
                  hodnotenia videa budú tiež odstránené.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Zrušiť</AlertDialogCancel>
                <AlertDialogAction onClick={confirmDeleteProblem}>
                  Vymazať
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </>
      )}

      <Dialog open={showAddProblem} onOpenChange={setShowAddProblem}>
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>Pridať nový matematický príklad</DialogTitle>
          </DialogHeader>
          <AddMathProblem
            onClose={() => setShowAddProblem(false)}
            onProblemAdded={handleProblemAdded}
            session={session}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={showCalendar} onOpenChange={setShowCalendar}>
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>Kalendár Dostupnosti</DialogTitle>
          </DialogHeader>
          <LessonCalendar
            isAdminUser={isAdminUser}
            onClose={() => setShowCalendar(false)}
          />
        </DialogContent>
      </Dialog>
      {isPrinting && <PrintingLoader />}
      <Dialog open={isPrinting} onOpenChange={setIsPrinting}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Export do PDF</DialogTitle>
            <DialogDescription>
              Zadajte nadpis svojho súboru PDF. Ten sa zobrazí v hornej časti
              prvej strany.
            </DialogDescription>
          </DialogHeader>
          <Input
            value={pdfHeadline}
            onChange={(e) => setPdfHeadline(e.target.value)}
            placeholder="Zadaj nadpis"
          />
          <DialogFooter>
            <Button onClick={() => setIsPrinting(false)}>Zrušiť</Button>
            <Button onClick={handlePrintPDF}>Generuj PDF</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <TestManager
        isOpen={showTestManager}
        onClose={() => setShowTestManager(false)}
        selectedProblems={selectedProblems}
      />
    </div>
  );
};

export default App;
