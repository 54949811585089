// src/components/MixedContent.js
import React from "react";
import { InlineMath, BlockMath } from "react-katex";

const MixedContent = ({ content }) => {
  const parts = content.split(/(\$.*?\$)/g);

  return (
    <span>
      {parts.map((part, index) => {
        if (part.startsWith("$") && part.endsWith("$")) {
          const mathExpression = part.slice(1, -1);
          return <InlineMath key={index} math={mathExpression} />;
        }
        return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
      })}
    </span>
  );
};

export default MixedContent;
