// In src/lib/mathProblemUtils.js

export const difficultyStyles = {
  nezaradené: {
    bgColor: "bg-white",
    textColor: "text-gray-800",
    borderColor: "border-gray-200",
  },
  ľahké: {
    bgColor: "bg-gradient-to-br from-green-50 to-green-100",
    textColor: "text-green-800",
    borderColor: "border-green-200",
  },
  stredné: {
    bgColor: "bg-gradient-to-br from-yellow-50 to-yellow-100",
    textColor: "text-yellow-800",
    borderColor: "border-yellow-200",
  },
  ťažké: {
    bgColor: "bg-gradient-to-br from-red-50 to-red-100",
    textColor: "text-red-800",
    borderColor: "border-red-200",
  },
};
