import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Trophy, Star } from "lucide-react";

const UserProgress = ({ streak, xp, onLevelUp }) => {
  const [prevXp, setPrevXp] = useState(xp);
  const [showPointsAnimation, setShowPointsAnimation] = useState(false);
  const level = Math.floor(xp / 100) + 1;
  const progress = (xp % 100) / 100;

  useEffect(() => {
    if (xp > prevXp) {
      setShowPointsAnimation(true);
      const timer = setTimeout(() => setShowPointsAnimation(false), 1500);

      if (Math.floor(xp / 100) > Math.floor(prevXp / 100)) {
        onLevelUp();
      }

      return () => clearTimeout(timer);
    }
    setPrevXp(xp);
  }, [xp, prevXp, onLevelUp]);

  return (
    <div className="flex items-center space-x-2 text-sm">
      <div className="flex items-center bg-indigo-100 rounded-full px-2 py-1">
        <Trophy className="w-3 h-3 mr-1 text-indigo-600" />
        <span className="font-semibold text-indigo-800">{streak}</span>
      </div>
      <div className="flex items-center bg-amber-100 rounded-full px-2 py-1">
        <Star className="w-3 h-3 mr-1 text-amber-600" />
        <span className="font-semibold text-amber-800">{xp}</span>
      </div>
      <div className="flex items-center space-x-1">
        <span className="text-xs font-medium text-indigo-600">Lvl {level}</span>
        <div className="w-16 h-1 bg-indigo-200 rounded-full overflow-hidden">
          <motion.div
            className="h-full bg-indigo-600"
            style={{ originX: 0 }}
            initial={{ scaleX: 0 }}
            animate={{ scaleX: progress }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          />
        </div>
      </div>
      <AnimatePresence>
        {showPointsAnimation && (
          <motion.div
            className="absolute top-full left-1/2 transform -translate-x-1/2"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          >
            <span className="text-xs font-bold text-indigo-600">
              +{xp - prevXp}
            </span>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserProgress;
