import { supabase } from "../components/supabaseClient";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"];

export const uploadImage = async (file, session) => {
  if (!file) {
    throw new Error("No file provided");
  }

  if (!session) {
    throw new Error("User must be authenticated to upload images");
  }

  if (!ALLOWED_FILE_TYPES.includes(file.type)) {
    throw new Error("Invalid file type. Only JPEG, PNG, and GIF are allowed.");
  }

  if (file.size > MAX_FILE_SIZE) {
    throw new Error("File size exceeds 5MB limit.");
  }

  try {
    const fileExt = file.name.split(".").pop();
    const fileName = `${Math.random()
      .toString(36)
      .substring(2, 15)}.${fileExt}`;
    const filePath = `math-problems/${session.user.id}/${fileName}`;

    const { data, error } = await supabase.storage
      .from("math-problem-images")
      .upload(filePath, file);

    if (error) {
      throw error;
    }

    // Get the public URL
    const { data: publicUrlData } = supabase.storage
      .from("math-problem-images")
      .getPublicUrl(filePath);

    return publicUrlData.publicUrl;
  } catch (error) {
    console.error("Error uploading image: ", error.message);
    throw error;
  }
};

export const deleteImage = async (url, session) => {
  if (!url || !session) return;

  try {
    // Extract the file path from the URL
    const urlObj = new URL(url);
    const filePath = urlObj.pathname.split("/").slice(3).join("/");

    const { data, error } = await supabase.storage
      .from("math-problem-images")
      .remove([filePath]);

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error("Error deleting image: ", error.message);
    throw error;
  }
};
