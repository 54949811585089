import React from "react";
import { Input } from "./ui/input";
import MixedContent from "./MixedContent";

const SubQuestions = ({
  subQuestions,
  userAnswers,
  setUserAnswers,
  onEnterPress,
}) => {
  const handleKeyPress = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputs = document.querySelectorAll('input[type="text"]');
      const currentIndex = Array.from(inputs).indexOf(e.target);
      if (currentIndex < inputs.length - 1) {
        inputs[currentIndex + 1].focus();
      } else {
        onEnterPress();
      }
    }
  };

  return (
    <div className="space-y-4">
      {subQuestions.map((subQ, index) => (
        <div key={index} className="space-y-2">
          <p className="font-medium">
            {String.fromCharCode(97 + index)}){" "}
            <MixedContent content={subQ.question} />
          </p>
          <Input
            type="text"
            value={userAnswers[index] || ""}
            onChange={(e) => {
              const newAnswers = [...userAnswers];
              newAnswers[index] = e.target.value;
              setUserAnswers(newAnswers);
            }}
            onKeyPress={(e) => handleKeyPress(e, index)}
            placeholder={`Odpoveď na podotázku ${String.fromCharCode(
              97 + index
            )}`}
          />
        </div>
      ))}
    </div>
  );
};

export default SubQuestions;
