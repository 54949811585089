// TestView.js
// Replace the existing content with this updated version

import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Button } from "./ui/button";
import { ChevronLeft, ChevronRight, ArrowLeft } from "lucide-react";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Progress } from "./ui/progress";
import MixedContent from "./MixedContent";
import { toast } from "react-hot-toast";
import { Input } from "./ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/dialog";

const TestView = ({ test, onBack, allTags }) => {
  const [problems, setProblems] = useState([]);
  const [currentProblemIndex, setCurrentProblemIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [userAnswers, setUserAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [testResults, setTestResults] = useState(null);

  useEffect(() => {
    if (test) {
      fetchTestProblems();
    }
  }, [test]);

  const fetchTestProblems = async () => {
    try {
      const { data, error } = await supabase
        .from("test_problems")
        .select(
          `
          *,
          math_problems (*)
        `
        )
        .eq("test_id", test.id)
        .order("id");

      if (error) throw error;

      const transformedProblems = data.map((tp) => ({
        ...tp.math_problems,
        test_id: test.id,
      }));

      setProblems(transformedProblems);
    } catch (error) {
      console.error("Error fetching test problems:", error);
      toast.error("Nepodarilo sa načítať príklady testu");
    } finally {
      setLoading(false);
    }
  };

  const currentProblem = problems[currentProblemIndex];

  const handleNext = () => {
    if (currentProblemIndex < problems.length - 1) {
      setCurrentProblemIndex((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentProblemIndex > 0) {
      setCurrentProblemIndex((prev) => prev - 1);
    }
  };

  const handleAnswerChange = (answer) => {
    setUserAnswers((prev) => ({
      ...prev,
      [currentProblem.id]: answer,
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNext();
    }
  };

  const evaluateTest = () => {
    const results = problems.map((problem) => {
      const userAnswer = userAnswers[problem.id] || "";
      const normalizedUserAnswer = userAnswer
        .replace(",", ".")
        .trim()
        .toLowerCase();
      const isCorrect = problem.answer.some(
        (ans) =>
          ans.replace(",", ".").trim().toLowerCase() === normalizedUserAnswer
      );
      return {
        problemId: problem.id,
        question: problem.question,
        userAnswer,
        correctAnswer: problem.answer[0],
        isCorrect,
      };
    });

    const totalCorrect = results.filter((r) => r.isCorrect).length;
    const percentage = (totalCorrect / problems.length) * 100;

    setTestResults({
      details: results,
      totalCorrect,
      percentage,
      totalQuestions: problems.length,
    });
    setShowResults(true);
  };

  const TestResults = () => (
    <Dialog
      open={showResults}
      onOpenChange={(open) => {
        if (!open) {
          setShowResults(false);
          onBack(); // This will navigate back to the test list
        }
      }}
    >
      {" "}
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Výsledky testu</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="text-center p-4">
            <h3 className="text-2xl font-bold mb-2">
              {testResults.percentage.toFixed(1)}%
            </h3>
            <p>
              Správne odpovede: {testResults.totalCorrect} z{" "}
              {testResults.totalQuestions}
            </p>
          </div>
          <div className="space-y-4">
            {testResults.details.map((result, index) => (
              <div
                key={result.problemId}
                className={`p-4 rounded-lg ${
                  result.isCorrect ? "bg-green-50" : "bg-red-50"
                }`}
              >
                <p className="font-medium mb-2">
                  {index + 1}. <MixedContent content={result.question} />
                </p>
                <div className="ml-4">
                  <p>Vaša odpoveď: {result.userAnswer || "Bez odpovede"}</p>
                  <p>Správna odpoveď: {result.correctAnswer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <DialogFooter>
          <Button onClick={onBack}>Späť na zoznam testov</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <Button onClick={onBack} variant="outline" className="mb-4">
        <ArrowLeft className="mr-2 h-4 w-4" />
        Späť na zoznam testov
      </Button>

      <Card className="mb-4">
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>{test.name}</CardTitle>
            <span className="text-sm text-gray-500">
              Príklad {currentProblemIndex + 1} z {problems.length}
            </span>
          </div>
        </CardHeader>
        <CardContent>
          <Progress
            value={(currentProblemIndex / problems.length) * 100}
            className="mb-4"
          />

          {currentProblem && (
            <div className="space-y-6">
              <div className="text-lg">
                <MixedContent content={currentProblem.question} />
              </div>

              {currentProblem.image_url && (
                <div className="my-4">
                  <img
                    src={currentProblem.image_url}
                    alt="Problem illustration"
                    className="max-w-full h-auto rounded-lg"
                  />
                </div>
              )}

              <div className="space-y-4">
                <Input
                  type="text"
                  placeholder="Zadajte odpoveď"
                  value={userAnswers[currentProblem.id] || ""}
                  onChange={(e) => handleAnswerChange(e.target.value)}
                  onKeyPress={handleKeyPress}
                />

                <div className="flex justify-between items-center">
                  <Button
                    onClick={handlePrevious}
                    disabled={currentProblemIndex === 0}
                    variant="outline"
                  >
                    <ChevronLeft className="mr-2 h-4 w-4" />
                    Predchádzajúci
                  </Button>

                  {currentProblemIndex === problems.length - 1 ? (
                    <Button
                      onClick={evaluateTest}
                      className="bg-green-600 hover:bg-green-700"
                    >
                      Vyhodnotiť test
                    </Button>
                  ) : (
                    <Button onClick={handleNext} variant="outline">
                      Ďalší
                      <ChevronRight className="ml-2 h-4 w-4" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      {showResults && <TestResults />}
    </div>
  );
};

export default TestView;
