import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { toast } from "react-hot-toast";

// AssignTask.js
const AssignTask = ({ onClose, onStudentAndDateSelected }) => {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [dueDate, setDueDate] = useState("");

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .select("id, email")
      .neq("email", "svojtko1@gmail.com");
    if (error) {
      console.error("Chyba pri načítaní študentov:", error);
    } else {
      setStudents(data);
    }
  };

  const handleNext = () => {
    if (selectedStudent && dueDate) {
      onStudentAndDateSelected(selectedStudent, dueDate);
      onClose();
    } else {
      toast.error("Prosím, vyberte študenta a nastavte termín");
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Priradiť matematický príklad</h2>
      <div>
        <Label htmlFor="student">Študent</Label>
        <Select value={selectedStudent} onValueChange={setSelectedStudent}>
          <SelectTrigger>
            <SelectValue placeholder="Vyberte študenta" />
          </SelectTrigger>
          <SelectContent>
            {students.map((student) => (
              <SelectItem key={student.id} value={student.id}>
                {student.email}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label htmlFor="dueDate">Termín odovzdania</Label>
        <Input
          type="date"
          id="dueDate"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
        />
      </div>
      <Button onClick={handleNext}>Ďalej</Button>
    </div>
  );
};

export default AssignTask;
