import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "./ui/card";
import { FileText, ChevronRight } from "lucide-react";
import { toast } from "react-hot-toast";

const TestSelector = ({ onTestSelect }) => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTests();
  }, []);

  const fetchTests = async () => {
    try {
      const { data, error } = await supabase
        .from("tests")
        .select(
          `
          *,
          test_problems (count)
        `
        )
        .order("created_at", { ascending: false });

      if (error) throw error;
      setTests(data);
    } catch (error) {
      console.error("Error fetching tests:", error);
      toast.error("Nepodarilo sa načítať testy");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center">
            <FileText className="mr-2 h-6 w-6" />
            Dostupné testy
          </CardTitle>
          <CardDescription>
            Vyberte test pre zobrazenie príslušných príkladov
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            {tests.map((test) => (
              <div
                key={test.id}
                onClick={() => onTestSelect(test)}
                className="flex items-center justify-between p-4 rounded-lg border border-gray-200 hover:border-primary hover:bg-gray-50 cursor-pointer transition-all"
              >
                <div className="flex items-center space-x-4">
                  <FileText className="h-5 w-5 text-gray-500" />
                  <div>
                    <h3 className="font-medium">{test.name}</h3>
                    <p className="text-sm text-gray-500">
                      Počet príkladov: {test.test_problems.count}
                    </p>
                  </div>
                </div>
                <ChevronRight className="h-5 w-5 text-gray-400" />
              </div>
            ))}
            {tests.length === 0 && !loading && (
              <div className="text-center text-gray-500 py-8">
                Momentálne nie sú k dispozícii žiadne testy
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TestSelector;
