// src/components/SubQuestionEditor.js
import React from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { PlusCircle, X } from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SubQuestionEditor = ({ subQuestions, setSubQuestions }) => {
  const addSubQuestion = () => {
    setSubQuestions([
      ...subQuestions,
      { question: "", answer: [""], type: "text", options: [] },
    ]);
  };

  const updateSubQuestion = (index, field, value) => {
    const updatedSubQuestions = [...subQuestions];
    updatedSubQuestions[index][field] = value;
    setSubQuestions(updatedSubQuestions);
  };

  const handleSubQuestionOptionChange = (
    subQuestionIndex,
    optionIndex,
    value
  ) => {
    const updatedSubQuestions = [...subQuestions];
    updatedSubQuestions[subQuestionIndex].options[optionIndex] = value;
    setSubQuestions(updatedSubQuestions);
  };

  const addSubQuestionOption = (subQuestionIndex) => {
    const updatedSubQuestions = [...subQuestions];
    updatedSubQuestions[subQuestionIndex].options.push("");
    setSubQuestions(updatedSubQuestions);
  };

  const removeSubQuestion = (index) => {
    setSubQuestions(subQuestions.filter((_, i) => i !== index));
  };

  return (
    <div className="space-y-4 flex flex-col">
      <Label>Podotázky</Label>
      {subQuestions.map((subQ, index) => (
        <div key={index} className="space-y-2 p-4 border  rounded">
          <ReactQuill
            theme="snow"
            value={subQ.question || ""}
            onChange={(content) =>
              updateSubQuestion(index, "question", content)
            }
            modules={{
              toolbar: [["bold", "italic", "underline"], ["formula"]],
            }}
          />
          <Select
            value={subQ.type || "text"}
            onValueChange={(value) => updateSubQuestion(index, "type", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Typ odpovede" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="text">Text</SelectItem>
              <SelectItem value="select">Výber z možností</SelectItem>
            </SelectContent>
          </Select>
          {(subQ.type || "text") === "text" ? (
            <Input
              value={(subQ.answer && subQ.answer[0]) || ""}
              onChange={(e) =>
                updateSubQuestion(index, "answer", [e.target.value])
              }
              placeholder="Odpoveď na podotázku"
            />
          ) : (
            <div className="space-y-2">
              {(subQ.options || []).map((option, optionIndex) => (
                <div key={optionIndex} className="flex items-center space-x-2">
                  <Input
                    value={option || ""}
                    onChange={(e) =>
                      handleSubQuestionOptionChange(
                        index,
                        optionIndex,
                        e.target.value
                      )
                    }
                    placeholder={`Možnosť ${optionIndex + 1}`}
                  />
                </div>
              ))}
              <Button
                type="button"
                variant="outline"
                onClick={() => addSubQuestionOption(index)}
              >
                Pridať možnosť
              </Button>
            </div>
          )}
          <Button
            type="button"
            variant="destructive"
            size="sm"
            onClick={() => removeSubQuestion(index)}
          >
            Odstrániť podotázku
          </Button>
        </div>
      ))}
      <Button
        type="button"
        onClick={addSubQuestion}
        variant="outline"
        className="max-w-52"
      >
        <PlusCircle className="h-4 w-4 mr-2 " />
        Pridať podotázku
      </Button>
    </div>
  );
};

export default SubQuestionEditor;
