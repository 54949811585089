// src/components/VideoRating.js

import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Button } from "./ui/button";
import { ThumbsUp, ThumbsDown } from "lucide-react";

const VideoRating = ({ problemId }) => {
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [userRating, setUserRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    fetchIpAddress();
    fetchRatings();
  }, [problemId]);

  const fetchIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      setIpAddress(data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  const fetchRatings = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("video_ratings")
      .select("rating")
      .eq("problem_id", problemId);

    if (error) {
      console.error("Error fetching video ratings:", error);
    } else {
      const likesCount = data.filter((r) => r.rating === 1).length;
      const dislikesCount = data.filter((r) => r.rating === -1).length;
      setLikes(likesCount);
      setDislikes(dislikesCount);
    }

    if (ipAddress) {
      const { data: userRatingData, error: userRatingError } = await supabase
        .from("video_ratings")
        .select("rating")
        .eq("problem_id", problemId)
        .eq("ip_address", ipAddress)
        .single();

      if (userRatingError && userRatingError.code !== "PGRST116") {
        console.error("Error fetching user rating:", userRatingError);
      } else {
        setUserRating(userRatingData?.rating || 0);
      }
    }

    setLoading(false);
  };

  const handleRating = async (rating) => {
    if (loading || !ipAddress) return;

    setLoading(true);
    const { error } = await supabase
      .from("video_ratings")
      .insert({ problem_id: problemId, ip_address: ipAddress, rating: rating });

    if (error) {
      console.error("Error rating video:", error);
    } else {
      if (rating === 1) setLikes(likes + 1);
      if (rating === -1) setDislikes(dislikes + 1);
      setUserRating(rating);
    }

    setLoading(false);
  };

  return (
    <div className="flex items-center space-x-2">
      <Button
        variant="outline"
        size="sm"
        onClick={() => handleRating(1)}
        disabled={loading || userRating !== 0}
      >
        <ThumbsUp
          className={userRating === 1 ? "text-green-600" : "text-gray-600"}
        />
        <span className="ml-1">{likes}</span>
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={() => handleRating(-1)}
        disabled={loading || userRating !== 0}
      >
        <ThumbsDown
          className={userRating === -1 ? "text-red-600" : "text-gray-600"}
        />
        <span className="ml-1">{dislikes}</span>
      </Button>
    </div>
  );
};

export default VideoRating;
