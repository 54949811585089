// src/components/VideoRequest.js
import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Button } from "./ui/button";
import { ArrowBigUp } from "lucide-react";
import { toast } from "react-hot-toast";
import { useIpAddress } from "../hooks/useIpAddress";

const VideoRequest = ({ problemId }) => {
  const [requests, setRequests] = useState(0);
  const [userRequested, setUserRequested] = useState(false);
  const [loading, setLoading] = useState(true);
  const ipAddress = useIpAddress();

  useEffect(() => {
    if (ipAddress && ipAddress !== "unknown") {
      fetchRequests();
    }
  }, [problemId, ipAddress]);

  const fetchRequests = async () => {
    const validProblemId = Number(problemId);
    if (isNaN(validProblemId)) {
      console.error("Invalid Problem ID:", problemId);
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      // First, get the total count of requests for this problem
      const { count, error: countError } = await supabase
        .from("video_requests")
        .select("*", { count: "exact", head: true })
        .eq("problem_id", validProblemId);

      if (countError) throw countError;

      setRequests(count || 0);

      // Then, check if the current user has made a request
      if (ipAddress && ipAddress !== "unknown") {
        const { data: userRequestData, error: userRequestError } =
          await supabase
            .from("video_requests")
            .select("id")
            .eq("problem_id", validProblemId)
            .eq("ip_address", ipAddress)
            .maybeSingle();

        if (userRequestError) throw userRequestError;

        setUserRequested(!!userRequestData);
      }
    } catch (error) {
      console.error("Error fetching video requests:", error);
      toast.error("Chyba pri načítaní žiadostí o video.");
    } finally {
      setLoading(false);
    }
  };

  const handleRequest = async () => {
    if (loading || ipAddress === "unknown" || userRequested) return;
    const validProblemId = Number(problemId);
    if (isNaN(validProblemId)) return;

    setLoading(true);
    try {
      const { error } = await supabase
        .from("video_requests")
        .insert({ problem_id: validProblemId, ip_address: ipAddress });

      if (error) throw error;

      setRequests((prevRequests) => prevRequests + 1);
      setUserRequested(true);
      toast.success("Ďakujem za váš záujem o video tutoriál!");
    } catch (error) {
      console.error("Error handling request:", error);
      if (error.code === "23505") {
        toast.error("Už ste hlasovali za tento príklad");
        setUserRequested(true);
      } else {
        toast.error("Chyba pri spracovaní žiadosti o video.");
      }
    } finally {
      setLoading(false);
      fetchRequests(); // Refresh the count after attempting to add a new request
    }
  };

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={(e) => {
        e.stopPropagation();
        handleRequest();
      }}
      disabled={loading || ipAddress === "unknown" || userRequested}
      className={`w-full sm:w-auto flex items-center justify-center sm:justify-start space-x-1 h-10 ${
        userRequested ? "bg-indigo-100 text-indigo-800" : ""
      }`}
    >
      <ArrowBigUp
        className={userRequested ? "text-indigo-600" : "text-gray-600"}
      />
      <span>{requests}</span>
      <span className="sm:inline">Žiadosť o video</span>
    </Button>
  );
};

export default VideoRequest;
