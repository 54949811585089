// src/components/UserProblemStatus.js
import React, { useState, useEffect, useMemo } from "react";
import { supabase } from "./supabaseClient";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { CheckCircle, XCircle, Clock } from "lucide-react";
import { format } from "date-fns";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import MixedContent from "./MixedContent";
import { Input } from "./ui/input";
import Fuse from "fuse.js";

const UserProblemStatus = () => {
  const [userProblems, setUserProblems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchUserProblems();
  }, []);

  const fetchUserProblems = async () => {
    setLoading(true);
    try {
      // Fetch all users except the admin
      const { data: users, error: userError } = await supabase
        .from("profiles")
        .select("id, email")
        .not("email", "eq", "admin@matikaodpatrika.sk");

      if (userError) throw userError;

      // Fetch all assigned problems with related math problem information
      const { data: assignedProblems, error: assignedError } =
        await supabase.from("assigned_problems").select(`
          id,
          student_id,
          problem_id,
          due_date,
          status,
          math_problems (question)
        `);

      if (assignedError) throw assignedError;

      // Create a map of assigned problems for each user
      const userProblemMap = users.reduce((acc, user) => {
        acc[user.id] = [];
        return acc;
      }, {});

      assignedProblems.forEach((assignment) => {
        if (userProblemMap[assignment.student_id]) {
          userProblemMap[assignment.student_id].push(assignment);
        }
      });

      // Combine all data
      const userProblemStatus = users.map((user) => ({
        ...user,
        assignments: userProblemMap[user.id] || [],
      }));

      setUserProblems(userProblemStatus);
    } catch (error) {
      console.error("Error fetching user problem status:", error);
    } finally {
      setLoading(false);
    }
  };

  const fuse = useMemo(() => {
    return new Fuse(userProblems, {
      keys: ["email"],
      threshold: 0.3, // You can adjust this value to make the search more or less strict
      ignoreLocation: true,
    });
  }, [userProblems]);

  const filteredUserProblems = useMemo(() => {
    if (!searchTerm) return userProblems;
    return fuse.search(searchTerm).map((result) => result.item);
  }, [fuse, searchTerm, userProblems]);

  const getStatusIcon = (status, dueDate) => {
    if (status === "completed") {
      return <CheckCircle className="text-green-500 inline" />;
    } else if (new Date(dueDate) < new Date()) {
      return <XCircle className="text-red-500 inline" />;
    } else {
      return <Clock className="text-yellow-500 inline" />;
    }
  };

  if (loading) {
    return <div>Načítavam...</div>;
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Stav úloh používateľov</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <Input
            type="text"
            placeholder="Vyhľadať používateľa podľa e-mailu"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="max-h-[70vh] overflow-y-auto">
          <Table>
            <TableHeader className="sticky top-0 bg-white z-10">
              <TableRow>
                <TableHead className="w-1/4">Používateľ</TableHead>
                <TableHead className="w-1/3">Priradený príklad</TableHead>
                <TableHead className="w-1/6">Termín</TableHead>
                <TableHead className="w-1/6">Stav</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredUserProblems.map((user) => (
                <React.Fragment key={user.id}>
                  <TableRow className="bg-gray-100">
                    <TableCell colSpan={4} className="font-bold">
                      {user.email}
                    </TableCell>
                  </TableRow>
                  {user.assignments.length > 0 ? (
                    user.assignments.map((assignment) => (
                      <TableRow key={assignment.id}>
                        <TableCell></TableCell>
                        <TableCell>
                          <MixedContent
                            content={assignment.math_problems.question}
                          />
                        </TableCell>
                        <TableCell>
                          {format(new Date(assignment.due_date), "dd.MM.yyyy")}
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center">
                            {getStatusIcon(
                              assignment.status,
                              assignment.due_date
                            )}
                            <span className="ml-2">{assignment.status}</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell colSpan={3}>
                        Žiadne priradené príklady
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default UserProblemStatus;
