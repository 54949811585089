import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { toast } from "react-hot-toast";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Pencil, Trash, Save, X } from "lucide-react";

const TagManager = () => {
  const [tags, setTags] = useState([]);
  const [newTagName, setNewTagName] = useState("");
  const [editingTag, setEditingTag] = useState(null);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const { data, error } = await supabase
        .from("tags")
        .select("*")
        .order("name");

      if (error) throw error;
      setTags(data);
    } catch (error) {
      console.error("Error fetching tags:", error);
      toast.error("Failed to fetch tags");
    }
  };

  const addTag = async () => {
    try {
      const { data, error } = await supabase
        .from("tags")
        .insert({ name: newTagName })
        .select();

      if (error) throw error;
      setTags([...tags, data[0]]);
      setNewTagName("");
      toast.success("Tag bol úspešne pridaný");
    } catch (error) {
      toast.error("Failed to add tag");
    }
  };

  const updateTag = async () => {
    try {
      const { data, error } = await supabase
        .from("tags")
        .update({ name: editingTag.name })
        .eq("id", editingTag.id)
        .select();

      if (error) throw error;
      setTags(tags.map((tag) => (tag.id === editingTag.id ? data[0] : tag)));
      setEditingTag(null);
      toast.success("Tag bol aktualizovaný");
    } catch (error) {
      toast.error("Nepodarilo sa aktualizovať tag");
    }
  };

  const deleteTag = async (id) => {
    try {
      const { error } = await supabase.from("tags").delete().eq("id", id);

      if (error) throw error;
      setTags(tags.filter((tag) => tag.id !== id));
      toast.success("Tag bol úspešne odstránený");
    } catch (error) {
      toast.error("Nepodarilo sa odstrániť tag");
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-2">
        <Input
          type="text"
          value={newTagName}
          onChange={(e) => setNewTagName(e.target.value)}
          placeholder="Názov nového tagu"
        />
        <Button onClick={addTag}>Pridaj tag</Button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Názov tagu</TableHead>
            <TableHead>Akcia</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tags.map((tag) => (
            <TableRow key={tag.id}>
              {editingTag && editingTag.id === tag.id ? (
                <TableCell>
                  <Input
                    type="text"
                    value={editingTag.name}
                    onChange={(e) =>
                      setEditingTag({ ...editingTag, name: e.target.value })
                    }
                  />
                </TableCell>
              ) : (
                <TableCell>{tag.name}</TableCell>
              )}
              <TableCell>
                {editingTag && editingTag.id === tag.id ? (
                  <>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={updateTag}
                      className="mr-2"
                    >
                      <Save className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => setEditingTag(null)}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => setEditingTag(tag)}
                      className="mr-2"
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => deleteTag(tag.id)}
                    >
                      <Trash className="h-4 w-4" />
                    </Button>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TagManager;
