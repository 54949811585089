import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Textarea } from "./ui/textarea";
import MixedContent from "./MixedContent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { uploadImage } from "../lib/imageUpload";
import { transformYouTubeUrl } from "../lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { difficultyStyles } from "../lib/mathProblemUtils";
import {
  X,
  PlusCircle,
  ChevronDown,
  ChevronUp,
  Image as ImageIcon,
} from "lucide-react";
import { toast } from "react-hot-toast";
import SearchableTagSelect from "./SearchableTagSelect";
import { Badge } from "./ui/badge";
import { Checkbox } from "./ui/checkbox";
import { Card, CardContent } from "./ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";

const VariantForm = ({ onSave, onClose, session }) => {
  const [variant, setVariant] = useState({
    question: "",
    answer: [""],
    difficulty: "",
    hint: "",
    image_url: "",
    video_tutorial_url: "",
    image_size: "medium",
    image_alignment: "center",
  });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTagSelect, setShowTagSelect] = useState(false);
  const [isMultipleChoice, setIsMultipleChoice] = useState(false);
  const [options, setOptions] = useState([]);
  const [correctOption, setCorrectOption] = useState(null);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const { data, error } = await supabase
        .from("tags")
        .select("*")
        .order("name");

      if (error) throw error;
      setAllTags(data);
    } catch (error) {
      console.error("Error fetching tags:", error);
      toast.error("Failed to fetch tags");
    }
  };

  const handleInputChange = (name, value) => {
    if (name === "video_tutorial_url") {
      value = transformYouTubeUrl(value);
    }
    setVariant((prev) => ({ ...prev, [name]: value }));
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...variant.answer];
    newAnswers[index] = value;
    setVariant((prev) => ({ ...prev, answer: newAnswers }));
  };

  const addAnswer = () => {
    setVariant((prev) => ({ ...prev, answer: [...prev.answer, ""] }));
  };

  const removeAnswer = (index) => {
    setVariant((prev) => ({
      ...prev,
      answer: prev.answer.filter((_, i) => i !== index),
    }));
  };

  const handleQuillChange = (content) => {
    handleInputChange("question", content);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleOptionChange = (index, content) => {
    const newOptions = [...options];
    newOptions[index] = content;
    setOptions(newOptions);
  };

  const handleCorrectOptionChange = (index) => {
    setCorrectOption(index);
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    try {
      let imageUrl = variant.image_url;
      if (imageFile) {
        imageUrl = await uploadImage(imageFile, session);
      }

      const variantData = {
        ...variant,
        image_url: imageUrl,
        options: isMultipleChoice ? options : null,
        answer: isMultipleChoice ? [options[correctOption]] : variant.answer,
        tags: selectedTags.map((tag) => tag.id),
      };

      onSave(variantData);
    } catch (error) {
      console.error("Error adding variant:", error.message);
      toast.error("Chyba pri pridávaní variantu: " + error.message);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <Card className="bg-white p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Pridať variant príkladu</h2>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="h-6 w-6" />
          </Button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <Tabs defaultValue="basic" className="w-full">
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="basic">Základné info</TabsTrigger>
              <TabsTrigger value="details">Detaily</TabsTrigger>
              <TabsTrigger value="media">Médiá</TabsTrigger>
            </TabsList>
            <TabsContent value="basic" className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="question">Otázka</Label>
                <ReactQuill
                  theme="snow"
                  value={variant.question}
                  onChange={handleQuillChange}
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link", "image"],
                      ["clean"],
                      ["formula"],
                    ],
                  }}
                />
                <div className="mt-2">
                  <Label>Náhľad:</Label>
                  <MixedContent content={variant.question} />
                </div>
              </div>

              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="isMultipleChoice"
                    checked={isMultipleChoice}
                    onCheckedChange={setIsMultipleChoice}
                  />
                  <Label htmlFor="isMultipleChoice">
                    Otázka s možnosťou výberu
                  </Label>
                </div>

                {isMultipleChoice ? (
                  <div className="space-y-2">
                    {options.map((option, index) => (
                      <div
                        key={index}
                        className="flex items-center space-x-2 mb-4"
                      >
                        <Checkbox
                          checked={index === correctOption}
                          onCheckedChange={() =>
                            handleCorrectOptionChange(index)
                          }
                        />
                        <div className="flex-grow">
                          <ReactQuill
                            value={option}
                            onChange={(content) =>
                              handleOptionChange(index, content)
                            }
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline"],
                                ["formula"],
                              ],
                            }}
                          />
                          <div className="mt-2 p-2 bg-gray-100 rounded">
                            <Label className="text-sm text-gray-600">
                              Náhľad:
                            </Label>
                            <MixedContent content={option} />
                          </div>
                        </div>
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => handleRemoveOption(index)}
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      </div>
                    ))}
                    <Button
                      type="button"
                      onClick={handleAddOption}
                      variant="outline"
                    >
                      <PlusCircle className="h-4 w-4 mr-2" />
                      Pridať možnosť
                    </Button>
                  </div>
                ) : (
                  <div className="space-y-2">
                    <Label htmlFor="answer">Odpovede</Label>
                    {variant.answer.map((ans, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <Input
                          value={ans}
                          onChange={(e) =>
                            handleAnswerChange(index, e.target.value)
                          }
                          placeholder={`Odpoveď ${index + 1}`}
                          required
                        />
                        <Button
                          type="button"
                          variant="outline"
                          size="icon"
                          onClick={() => removeAnswer(index)}
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      </div>
                    ))}
                    <Button type="button" onClick={addAnswer} variant="outline">
                      <PlusCircle className="h-4 w-4 mr-2" />
                      Pridať ďalšiu odpoveď
                    </Button>
                  </div>
                )}
              </div>
            </TabsContent>

            <TabsContent value="details" className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="difficulty">Obtiažnosť</Label>
                  <Select
                    value={variant.difficulty}
                    onValueChange={(value) =>
                      handleInputChange("difficulty", value)
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Vyberte obtiažnosť" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.keys(difficultyStyles).map((difficulty) => (
                        <SelectItem key={difficulty} value={difficulty}>
                          {difficulty}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="hint">Nápoveda (voliteľné)</Label>
                <Textarea
                  id="hint"
                  value={variant.hint}
                  onChange={(e) => handleInputChange("hint", e.target.value)}
                  placeholder="Zadajte nápovedu"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="tags">Tagy</Label>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setShowTagSelect(!showTagSelect)}
                  className="w-full justify-between"
                >
                  <span>Vybrať tagy ({selectedTags.length} vybrané)</span>
                  {showTagSelect ? (
                    <ChevronUp className="h-4 w-4" />
                  ) : (
                    <ChevronDown className="h-4 w-4" />
                  )}
                </Button>
                {showTagSelect && (
                  <SearchableTagSelect
                    allTags={allTags}
                    selectedTags={selectedTags}
                    onChange={setSelectedTags}
                  />
                )}
                {selectedTags.length > 0 && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {selectedTags.map((tag) => (
                      <Badge
                        key={tag.id}
                        variant="secondary"
                        className="text-sm"
                      >
                        {tag.name}
                        <button
                          type="button"
                          onClick={() =>
                            setSelectedTags(
                              selectedTags.filter((t) => t.id !== tag.id)
                            )
                          }
                          className="ml-1 text-gray-500 hover:text-gray-700"
                        >
                          ×
                        </button>
                      </Badge>
                    ))}
                  </div>
                )}
              </div>
            </TabsContent>

            <TabsContent value="media" className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="image">Obrázok (voliteľné)</Label>
                <div className="flex items-center space-x-2">
                  <Input
                    id="image"
                    type="file"
                    accept="image/jpeg,image/png,image/gif"
                    onChange={handleImageChange}
                    className="flex-grow"
                  />
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="w-16 h-16 object-cover rounded"
                    />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label htmlFor="image_size">Veľkosť obrázku</Label>
                  <Select
                    value={variant.image_size}
                    onValueChange={(value) =>
                      handleInputChange("image_size", value)
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Vyberte veľkosť" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="small">Malý</SelectItem>
                      <SelectItem value="medium">Stredný</SelectItem>
                      <SelectItem value="large">Veľký</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="image_alignment">Zarovnanie obrázku</Label>
                  <Select
                    value={variant.image_alignment}
                    onValueChange={(value) =>
                      handleInputChange("image_alignment", value)
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Vyberte zarovnanie" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="left">Vľavo</SelectItem>
                      <SelectItem value="center">Na stred</SelectItem>
                      <SelectItem value="right">Vpravo</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="video_tutorial_url">
                  Video tutoriál URL (voliteľné)
                </Label>
                <Input
                  id="video_tutorial_url"
                  type="url"
                  value={variant.video_tutorial_url}
                  onChange={(e) =>
                    handleInputChange("video_tutorial_url", e.target.value)
                  }
                  placeholder="https://www.youtube.com/watch?v=..."
                />
              </div>
            </TabsContent>
          </Tabs>
          <div className="flex justify-end space-x-2 pt-4">
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
              disabled={isUploading}
            >
              Zrušiť
            </Button>
            <Button type="submit" disabled={isUploading}>
              {isUploading ? "Pridávam..." : "Pridať variant"}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default VariantForm;
