import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Progress } from "./ui/progress";
import { Alert, AlertDescription } from "./ui/alert";
import { InfoIcon, Zap, CalendarDays, Star } from "lucide-react";
import { format, subDays, eachDayOfInterval } from "date-fns";
import { sk } from "date-fns/locale";

const Statistics = ({ userId, onViewProblem }) => {
  const [userStats, setUserStats] = useState({
    streak: 0,
    xp: 0,
    currentLevel: 1,
    levelProgress: 0,
  });
  const [loading, setLoading] = useState(true);
  const [streakData, setStreakData] = useState([]);

  useEffect(() => {
    fetchUserStatistics();
  }, [userId]);

  const fetchUserStatistics = async () => {
    try {
      const { data: userProgress, error: userProgressError } = await supabase
        .from("user_progress")
        .select("*")
        .eq("user_id", userId)
        .single();

      if (userProgressError) throw userProgressError;

      setUserStats({
        streak: userProgress.streak || 0,
        xp: userProgress.xp || 0,
        currentLevel: Math.floor((userProgress.xp || 0) / 100) + 1,
        levelProgress: (userProgress.xp || 0) % 100,
      });

      // Generate streak data
      const streakDays = generateStreakData(userProgress.streak || 0);
      setStreakData(streakDays);
    } catch (error) {
      console.error("Error fetching user statistics:", error);
    } finally {
      setLoading(false);
    }
  };

  const generateStreakData = (streakCount) => {
    const today = new Date();
    const startDate = subDays(today, 29); // Show last 30 days including today
    const dateRange = eachDayOfInterval({ start: startDate, end: today });

    return dateRange.map((date, index) => ({
      date,
      completed: index >= dateRange.length - streakCount,
    }));
  };

  if (loading) {
    return <div>Načítavam štatistiky...</div>;
  }

  return (
    <div className="space-y-6 p-4 bg-gray-50 rounded-lg">
      <Alert>
        <InfoIcon className="h-4 w-4" />
        <AlertDescription>
          Tieto štatistiky zobrazujú váš aktuálny pokrok v matematike.
        </AlertDescription>
      </Alert>

      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-indigo-700">
            Váš matematický progres
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <StatCard
              icon={<CalendarDays className="h-8 w-8 text-orange-500" />}
              title="Aktuálny streak"
              value={`${userStats.streak} dní`}
              color="orange"
            />
            <StatCard
              icon={<Star className="h-8 w-8 text-yellow-500" />}
              title="Celkové XP"
              value={userStats.xp}
              color="yellow"
            />
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2 text-indigo-700">
              Váš level
            </h3>
            <div className="flex items-center">
              <Zap className="h-6 w-6 text-yellow-500 mr-2" />
              <div className="flex-grow">
                <Progress value={userStats.levelProgress} className="h-2" />
              </div>
              <span className="ml-2 font-bold text-indigo-700">
                Level {userStats.currentLevel}
              </span>
            </div>
            <p className="text-sm text-gray-500 mt-1">
              XP do ďalšieho levelu: {100 - userStats.levelProgress}
            </p>
          </div>

          <StreakCalendar streakData={streakData} />
        </CardContent>
      </Card>
    </div>
  );
};

const StatCard = ({ icon, title, value, color }) => (
  <div
    className={`flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow`}
  >
    <div className={`mb-2 text-${color}-500`}>{icon}</div>
    <p className="text-sm font-medium text-gray-500">{title}</p>
    <p className={`text-3xl font-bold text-${color}-700`}>{value}</p>
  </div>
);

const StreakCalendar = ({ streakData }) => (
  <div className="mt-6">
    <h3 className="text-lg font-semibold mb-2 text-indigo-700">
      Kalendár streaku
    </h3>
    <div className="grid grid-cols-7 gap-1">
      {streakData.map((day, index) => (
        <div
          key={index}
          className={`w-6 h-6 rounded-sm ${
            day.completed ? "bg-green-500" : "bg-gray-200"
          }`}
          title={format(day.date, "dd.MM.yyyy", { locale: sk })}
        />
      ))}
    </div>
    <p className="text-sm text-gray-500 mt-2">
      Zelené štvorčeky označujú dni vášho aktuálneho streaku.
    </p>
  </div>
);

export default Statistics;
