import React, { useState, useRef, useEffect } from "react";
import { Command } from "cmdk";
import { Check, PlusCircle, MinusCircle } from "lucide-react";
import { Button } from "./ui/button";
import { ScrollArea } from "./ui/scroll-area";

const SearchableTagSelect = ({
  allTags,
  includedTags = [],
  excludedTags = [],
  onTagToggle,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);

  const filteredTags = allTags.filter((tag) =>
    tag.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleTagToggle = (tagId, action) => {
    onTagToggle(tagId, action);
    setSearchTerm("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Command className="rounded-lg border shadow-md">
      <Command.Input
        ref={inputRef}
        placeholder="Hľadať tagy..."
        value={searchTerm}
        onValueChange={setSearchTerm}
        className="w-full p-2"
      />
      <ScrollArea className="h-[300px]">
        <Command.List className="max-h-full overflow-y-auto">
          {filteredTags.map((tag) => (
            <Command.Item
              key={tag.id}
              className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
            >
              <Button
                type="button" // Add explicit type="button"
                variant="ghost"
                size="sm"
                className={`mr-2 ${
                  includedTags.includes(tag.id)
                    ? "text-green-500"
                    : "text-gray-400"
                }`}
                onClick={(e) => {
                  e.preventDefault(); // Prevent any form submission
                  handleTagToggle(tag.id, "include");
                }}
              >
                <PlusCircle size={16} />
              </Button>
              <Button
                type="button" // Add explicit type="button"
                variant="ghost"
                size="sm"
                className={`mr-2 ${
                  excludedTags.includes(tag.id)
                    ? "text-red-500"
                    : "text-gray-400"
                }`}
                onClick={(e) => {
                  e.preventDefault(); // Prevent any form submission
                  handleTagToggle(tag.id, "exclude");
                }}
              >
                <MinusCircle size={16} />
              </Button>
              <span className="flex-grow">{tag.name}</span>
              {(includedTags.includes(tag.id) ||
                excludedTags.includes(tag.id)) && (
                <Check size={16} className="text-blue-500" />
              )}
            </Command.Item>
          ))}
        </Command.List>
      </ScrollArea>
    </Command>
  );
};

export default SearchableTagSelect;
