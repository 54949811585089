import React from "react";
import { Badge } from "./ui/badge";
import { Button } from "./ui/button";
import { Info } from "lucide-react";

const ProblemMetadata = ({
  tagIds,
  allTags,
  difficulty,
  isVisible,
  onToggle,
}) => {
  const getTagName = (tagId) => {
    const tag = allTags.find((t) => t.id === tagId);
    return tag ? tag.name : "Unknown Tag";
  };

  return (
    <div className="relative">
      <Button
        variant="ghost"
        size="sm"
        onClick={onToggle}
        className="absolute top-0 right-0 p-1"
        title={isVisible ? "Hide metadata" : "Show metadata"}
      >
        <Info className="h-5 w-5" />
      </Button>
      {isVisible && (
        <div className="pt-2 pb-4">
          <div className="flex flex-wrap items-center gap-2">
            {tagIds &&
              tagIds.length > 0 &&
              tagIds.map((tagId) => (
                <Badge key={tagId} variant="secondary" className="text-sm">
                  {getTagName(tagId)}
                </Badge>
              ))}
            <Badge variant="outline" className="text-sm">
              {difficulty}
            </Badge>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProblemMetadata;
