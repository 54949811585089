// src/components/PrintingLoader.js
import React from "react";
import { Loader2 } from "lucide-react";

const PrintingLoader = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg flex items-center space-x-4">
        <Loader2 className="animate-spin h-6 w-6 text-indigo-600" />
        <span className="text-lg font-medium text-gray-900">
          Generujem PDF...
        </span>
      </div>
    </div>
  );
};

export default PrintingLoader;
