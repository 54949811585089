import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Textarea } from "./ui/textarea";
import MixedContent from "./MixedContent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { uploadImage, deleteImage } from "../lib/imageUpload";
import SubQuestionEditor from "./SubQuestionEditor";

import { transformYouTubeUrl } from "../lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { difficultyStyles } from "../lib/mathProblemUtils";
import {
  X,
  PlusCircle,
  Edit,
  Trash,
  ChevronDown,
  ChevronUp,
  Image as ImageIcon,
} from "lucide-react";
import { toast } from "react-hot-toast";
import VariantForm from "./VariantForm";
import SearchableTagSelect from "./SearchableTagSelect";
import { Badge } from "./ui/badge";
import { Checkbox } from "./ui/checkbox";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";

const EditMathProblem = ({
  problem,
  onClose,
  onProblemUpdated,
  session,
  open,
}) => {
  console.log("Initial problem prop:", problem);

  const [editedProblem, setEditedProblem] = useState({
    ...problem,
    answer: Array.isArray(problem.answer) ? problem.answer : [problem.answer],
    image_size: problem.image_size || "medium",
    image_alignment: problem.image_alignment || "center",
    difficulty: problem.difficulty || "nezaradené",
  });
  const [variants, setVariants] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showVariantForm, setShowVariantForm] = useState(false);
  const [editingVariant, setEditingVariant] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTagSelect, setShowTagSelect] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(problem.image_url);
  const [isUploading, setIsUploading] = useState(false);
  const [isMultipleChoice, setIsMultipleChoice] = useState(!!problem.options);
  const [subQuestions, setSubQuestions] = useState(
    editedProblem.sub_questions && editedProblem.sub_questions.length > 0
      ? editedProblem.sub_questions
      : []
  );
  const [options, setOptions] = useState(problem.options || []);
  const [correctOption, setCorrectOption] = useState(
    problem.options
      ? problem.options.findIndex((opt) => opt === problem.answer[0])
      : null
  );

  useEffect(() => {
    fetchTags();
    fetchVariants();
  }, []);

  const addSubQuestion = () => {
    setSubQuestions([
      ...subQuestions,
      { question: "", answer: [""], type: "text", options: [] },
    ]);
  };

  const updateSubQuestion = (index, field, value) => {
    const updatedSubQuestions = [...subQuestions];
    updatedSubQuestions[index][field] = value;
    setSubQuestions(updatedSubQuestions);
  };

  const handleSubQuestionOptionChange = (
    subQuestionIndex,
    optionIndex,
    value
  ) => {
    const updatedSubQuestions = [...subQuestions];
    updatedSubQuestions[subQuestionIndex].options[optionIndex] = value;
    setSubQuestions(updatedSubQuestions);
  };

  const addSubQuestionOption = (subQuestionIndex) => {
    const updatedSubQuestions = [...subQuestions];
    updatedSubQuestions[subQuestionIndex].options.push("");
    setSubQuestions(updatedSubQuestions);
  };

  const removeSubQuestion = (index) => {
    setSubQuestions(subQuestions.filter((_, i) => i !== index));
  };

  const fetchTags = async () => {
    try {
      const { data, error } = await supabase
        .from("tags")
        .select("*")
        .order("name");

      if (error) throw error;
      setAllTags(data);

      const { data: problemTags, error: problemTagsError } = await supabase
        .from("math_problem_tags")
        .select("tag_id")
        .eq("problem_id", problem.id);

      if (problemTagsError) throw problemTagsError;

      setSelectedTags(problemTags.map((pt) => pt.tag_id));
    } catch (error) {
      console.error("Error fetching tags:", error);
      toast.error("Failed to fetch tags");
    }
  };

  const fetchVariants = async () => {
    try {
      const { data, error } = await supabase
        .from("math_problems")
        .select("*")
        .eq("main_problem_id", problem.id);

      if (error) throw error;
      setVariants(data || []);
    } catch (error) {
      console.error("Error fetching variants:", error);
      toast.error("Failed to fetch variants");
    }
  };

  const handleInputChange = (name, value) => {
    if (name === "video_tutorial_url") {
      value = transformYouTubeUrl(value);
    }
    setEditedProblem((prev) => ({ ...prev, [name]: value }));
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...editedProblem.answer];
    newAnswers[index] = value;
    setEditedProblem((prev) => ({ ...prev, answer: newAnswers }));
  };

  const addAnswer = () => {
    setEditedProblem((prev) => ({ ...prev, answer: [...prev.answer, ""] }));
  };

  const removeAnswer = (index) => {
    setEditedProblem((prev) => ({
      ...prev,
      answer: prev.answer.filter((_, i) => i !== index),
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleQuillChange = (content, delta, source, editor) => {
    if (source === "user") {
      const regex = /\$(.*?)\$/g;
      const newContent = content.replace(regex, (match, p1) => {
        return "$" + p1.replace(/</g, "\\lt") + "$";
      });
      handleInputChange("question", newContent);
    }
  };

  const handleAddVariant = () => {
    setEditingVariant(null);
    setShowVariantForm(true);
  };

  const handleEditVariant = (variant) => {
    setEditingVariant(variant);
    setShowVariantForm(true);
  };

  const handleDeleteVariant = async (variantId) => {
    try {
      const { error } = await supabase
        .from("math_problems")
        .delete()
        .eq("id", variantId);

      if (error) throw error;

      setVariants(variants.filter((v) => v.id !== variantId));
      toast.success("Variant bol úspešne vymazaný");
    } catch (error) {
      console.error("Error deleting variant:", error);
      toast.error("Chyba pri mazaní variantu");
    }
  };

  const handleSaveVariant = async (variantData) => {
    try {
      if (editingVariant) {
        const { data, error } = await supabase
          .from("math_problems")
          .update(variantData)
          .eq("id", editingVariant.id)
          .select();

        if (error) throw error;

        setVariants(
          variants.map((v) => (v.id === editingVariant.id ? data[0] : v))
        );
        toast.success("Variant bol úspešne aktualizovaný");
      } else {
        const { data, error } = await supabase
          .from("math_problems")
          .insert({ ...variantData, main_problem_id: problem.id })
          .select();

        if (error) throw error;

        setVariants([...variants, data[0]]);
        toast.success("Nový variant bol úspešne pridaný");
      }

      setShowVariantForm(false);
      setEditingVariant(null);
    } catch (error) {
      console.error("Error saving variant:", error);
      toast.error("Chyba pri ukladaní variantu");
    }
  };

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleOptionChange = (index, content) => {
    const newOptions = [...options];
    newOptions[index] = content;
    setOptions(newOptions);
  };

  const handleCorrectOptionChange = (index) => {
    setCorrectOption(index);
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsUploading(true);
    try {
      let imageUrl = editedProblem.image_url;
      if (imageFile) {
        if (problem.image_url) {
          await deleteImage(problem.image_url, session);
        }
        imageUrl = await uploadImage(imageFile, session);
      }

      const updatedFields = {
        question: editedProblem.question,
        difficulty: editedProblem.difficulty,
        hint: editedProblem.hint,
        image_url: imageUrl,
        tags: selectedTags.map((tag) =>
          typeof tag === "object" ? tag.id : tag
        ),
        video_tutorial_url: transformYouTubeUrl(
          editedProblem.video_tutorial_url
        ),
        image_size: editedProblem.image_size,
        image_alignment: editedProblem.image_alignment,
        options: isMultipleChoice ? options : null,
        answer: isMultipleChoice
          ? [options[correctOption]]
          : editedProblem.answer,
        sub_questions: subQuestions,
      };

      const { data, error } = await supabase
        .from("math_problems")
        .update(updatedFields)
        .eq("id", problem.id)
        .select();

      if (error) throw error;

      await supabase
        .from("math_problem_tags")
        .delete()
        .eq("problem_id", problem.id);

      if (selectedTags.length > 0) {
        const tagAssociations = selectedTags.map((tagId) => ({
          problem_id: problem.id,
          tag_id: tagId,
        }));

        const { error: tagAssociationError } = await supabase
          .from("math_problem_tags")
          .insert(tagAssociations);

        if (tagAssociationError) throw tagAssociationError;
      }

      if (data && data.length > 0) {
        const updatedProblem = { ...data[0], tags: selectedTags };
        onProblemUpdated(updatedProblem);
        onClose();
        toast.success("Príklad bol úspešne aktualizovaný");
      } else {
        throw new Error("No data returned from update operation");
      }
    } catch (error) {
      console.error("Error updating problem:", error.message);
      toast.error("Chyba pri aktualizácii príkladu: " + error.message);
    } finally {
      setIsSubmitting(false);
      setIsUploading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Upraviť matematický príklad</DialogTitle>
        </DialogHeader>
        <div className="space-y-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <Tabs defaultValue="basic" className="w-full">
              <TabsList className="grid w-full grid-cols-4">
                <TabsTrigger value="basic">Základné info</TabsTrigger>
                <TabsTrigger value="details">Detaily</TabsTrigger>
                <TabsTrigger value="media">Médiá</TabsTrigger>
                <TabsTrigger value="variants">Varianty</TabsTrigger>
              </TabsList>
              <TabsContent value="basic" className="space-y-4">
                {/* Existing question input */}
                <div className="space-y-2">
                  <Label htmlFor="question">Otázka</Label>
                  <ReactQuill
                    theme="snow"
                    value={editedProblem.question}
                    onChange={handleQuillChange}
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image"],
                        ["clean"],
                        ["formula"],
                      ],
                    }}
                  />
                  <div className="mt-2">
                    <Label>Náhľad:</Label>
                    <MixedContent content={editedProblem.question} />
                  </div>
                </div>

                {/* Subquestions */}
                <SubQuestionEditor
                  subQuestions={subQuestions}
                  setSubQuestions={setSubQuestions}
                />

                <div className="space-y-2">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="isMultipleChoice"
                      checked={isMultipleChoice}
                      onCheckedChange={setIsMultipleChoice}
                    />
                    <Label htmlFor="isMultipleChoice">
                      Otázka s možnosťou výberu
                    </Label>
                  </div>

                  {isMultipleChoice ? (
                    <div className="space-y-2">
                      {options.map((option, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-2 mb-4"
                        >
                          <Checkbox
                            checked={index === correctOption}
                            onCheckedChange={() =>
                              handleCorrectOptionChange(index)
                            }
                          />
                          <div className="flex-grow">
                            <ReactQuill
                              value={option}
                              onChange={(content) =>
                                handleOptionChange(index, content)
                              }
                              modules={{
                                toolbar: [
                                  ["bold", "italic", "underline"],
                                  ["formula"],
                                ],
                              }}
                            />
                            <div className="mt-2 p-2 bg-gray-100 rounded">
                              <Label className="text-sm text-gray-600">
                                Náhľad:
                              </Label>
                              <MixedContent content={option} />
                            </div>
                          </div>
                          <Button
                            type="button"
                            variant="outline"
                            onClick={() => handleRemoveOption(index)}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      ))}
                      <Button
                        type="button"
                        onClick={handleAddOption}
                        variant="outline"
                      >
                        <PlusCircle className="h-4 w-4 mr-2" />
                        Pridať možnosť
                      </Button>
                    </div>
                  ) : (
                    <div className="space-y-2">
                      <Label htmlFor="answer">Odpovede</Label>
                      {editedProblem.answer.map((ans, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-2"
                        >
                          <Input
                            value={ans}
                            onChange={(e) =>
                              handleAnswerChange(index, e.target.value)
                            }
                            placeholder={`Odpoveď ${index + 1}`}
                            required
                          />
                          <Button
                            type="button"
                            variant="outline"
                            size="icon"
                            onClick={() => removeAnswer(index)}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      ))}
                      <Button
                        type="button"
                        onClick={addAnswer}
                        variant="outline"
                      >
                        <PlusCircle className="h-4 w-4 mr-2" />
                        Pridať ďalšiu odpoveď
                      </Button>
                    </div>
                  )}
                </div>
              </TabsContent>

              <TabsContent value="details" className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="difficulty">Obtiažnosť</Label>
                    <Select
                      value={editedProblem.difficulty || "nezaradené"}
                      onValueChange={(value) =>
                        handleInputChange("difficulty", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Vyberte obtiažnosť" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="nezaradené">Nezaradené</SelectItem>
                        <SelectItem value="ľahké">Ľahké</SelectItem>
                        <SelectItem value="stredné">Stredné</SelectItem>
                        <SelectItem value="ťažké">Ťažké</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="hint">Nápoveda (voliteľné)</Label>
                  <Textarea
                    id="hint"
                    value={editedProblem.hint}
                    onChange={(e) => handleInputChange("hint", e.target.value)}
                    placeholder="Zadajte nápovedu"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="tags">Tagy</Label>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setShowTagSelect(!showTagSelect)}
                    className="w-full justify-between"
                  >
                    <span>Vybrať tagy ({selectedTags.length} vybrané)</span>
                    {showTagSelect ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </Button>
                  {showTagSelect && (
                    <SearchableTagSelect
                      allTags={allTags}
                      selectedTags={selectedTags}
                      onChange={setSelectedTags}
                      onTagToggle={(tagId) => {
                        setSelectedTags((prev) =>
                          prev.includes(tagId)
                            ? prev.filter((id) => id !== tagId)
                            : [...prev, tagId]
                        );
                      }}
                    />
                  )}
                  {selectedTags.length > 0 && (
                    <div className="flex flex-wrap gap-2 mt-2">
                      {selectedTags.map((tagId) => {
                        const tag = allTags.find((t) => t.id === tagId);
                        return tag ? (
                          <Badge
                            key={tagId}
                            variant="secondary"
                            className="text-sm"
                          >
                            {tag.name}
                            <button
                              type="button"
                              onClick={() =>
                                setSelectedTags(
                                  selectedTags.filter((id) => id !== tagId)
                                )
                              }
                              className="ml-1 text-gray-500 hover:text-gray-700"
                            >
                              ×
                            </button>
                          </Badge>
                        ) : null;
                      })}
                    </div>
                  )}
                </div>
              </TabsContent>

              <TabsContent value="media" className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="image">Obrázok (voliteľné)</Label>
                  <div className="flex items-center space-x-2">
                    <Input
                      id="image"
                      type="file"
                      accept="image/jpeg,image/png,image/gif"
                      onChange={handleImageChange}
                      className="flex-grow"
                    />
                    {imagePreview && (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="w-16 h-16 object-cover rounded"
                      />
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="image_size">Veľkosť obrázku</Label>
                    <Select
                      value={editedProblem.image_size}
                      onValueChange={(value) =>
                        handleInputChange("image_size", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Vyberte veľkosť" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="small">Malý</SelectItem>
                        <SelectItem value="medium">Stredný</SelectItem>
                        <SelectItem value="large">Veľký</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <Label htmlFor="image_alignment">Zarovnanie obrázku</Label>
                    <Select
                      value={editedProblem.image_alignment}
                      onValueChange={(value) =>
                        handleInputChange("image_alignment", value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Vyberte zarovnanie" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="left">Vľavo</SelectItem>
                        <SelectItem value="center">Na stred</SelectItem>
                        <SelectItem value="right">Vpravo</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="video_tutorial_url">
                    Video tutoriál URL (voliteľné)
                  </Label>
                  <Input
                    id="video_tutorial_url"
                    type="url"
                    value={editedProblem.video_tutorial_url}
                    onChange={(e) =>
                      handleInputChange("video_tutorial_url", e.target.value)
                    }
                    placeholder="https://www.youtube.com/watch?v=..."
                  />
                </div>
              </TabsContent>

              <TabsContent value="variants" className="space-y-4">
                <div className="space-y-2">
                  <Label>Obdobné príklady</Label>
                  <div className="space-y-2">
                    {variants.map((variant) => (
                      <div
                        key={variant.id}
                        className="flex items-center space-x-2 bg-gray-100 p-2 rounded"
                      >
                        <div className="flex-grow">
                          <p className="font-medium truncate">
                            <MixedContent content={variant.question} />
                          </p>
                          <p className="text-sm text-gray-600 truncate">
                            Odpoveď:{" "}
                            {Array.isArray(variant.answer) ? (
                              variant.answer.map((ans, i) => (
                                <span key={i} className="mr-2">
                                  <MixedContent content={ans} />
                                </span>
                              ))
                            ) : (
                              <MixedContent content={variant.answer} />
                            )}
                          </p>
                        </div>
                        <Button
                          type="button"
                          variant="outline"
                          size="sm"
                          onClick={() => handleEditVariant(variant)}
                        >
                          <Edit className="h-4 w-4" />
                        </Button>
                        <Button
                          type="button"
                          variant="destructive"
                          size="sm"
                          onClick={() => handleDeleteVariant(variant.id)}
                        >
                          <Trash className="h-4 w-4" />
                        </Button>
                      </div>
                    ))}
                  </div>
                  <Button
                    type="button"
                    onClick={handleAddVariant}
                    className="mt-2"
                  >
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Pridať obdobný príklad
                  </Button>
                </div>
              </TabsContent>
            </Tabs>
            <div className="flex justify-end space-x-2 pt-4">
              <Button
                type="button"
                variant="outline"
                onClick={onClose}
                disabled={isSubmitting || isUploading}
              >
                Zrušiť
              </Button>
              <Button type="submit" disabled={isSubmitting || isUploading}>
                {isSubmitting || isUploading
                  ? "Aktualizujem..."
                  : "Aktualizovať príklad"}
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>

      <Dialog
        open={showVariantForm}
        onOpenChange={() => setShowVariantForm(false)}
      >
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>
              {editingVariant ? "Upraviť variant" : "Pridať variant"}
            </DialogTitle>
          </DialogHeader>
          <VariantForm
            variant={editingVariant}
            onSave={handleSaveVariant}
            onClose={() => {
              setShowVariantForm(false);
              setEditingVariant(null);
            }}
            session={session}
          />
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

export default EditMathProblem;
