import React, { useState } from "react";
import { supabase } from "./supabaseClient";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Alert, AlertDescription } from "./ui/alert";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { Label } from "./ui/label";

export function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("student");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
    } catch (error) {
      // Translate the error message to Slovak
      if (error.message === "Invalid login credentials") {
        setError("Nesprávne prihlasovacie údaje");
      } else if (error.message === "Email not confirmed") {
        setError("Email nie je potvrdený");
      } else {
        setError("Nastala chyba pri prihlásení");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { user, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            role: role,
          },
        },
      });
      if (error) throw error;

      if (user) {
        const { error: roleError } = await supabase
          .from("user_roles")
          .insert({ user_id: user.id, role });

        if (roleError) throw roleError;
      }

      setError("Skontrolujte svoj email pre potvrdzovací odkaz!");
    } catch (error) {
      // Translate the error message to Slovak
      if (error.message.includes("Password should be at least 6 characters")) {
        setError("Heslo by malo mať aspoň 6 znakov");
      } else if (error.message.includes("User already registered")) {
        setError("Užívateľ s týmto emailom už existuje");
      } else {
        setError("Nastala chyba pri registrácii");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="w-[350px]">
      <CardHeader>
        <div className="flex items-center justify-center mb-4">
          <img
            src="/matpat.png"
            alt="Matika od Patrika Logo"
            className="max-w-full h-auto w-28 md:w-22"
          />
        </div>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="login">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="login">Prihlásiť sa</TabsTrigger>
            <TabsTrigger value="signup">Registrovať sa</TabsTrigger>
          </TabsList>
          <TabsContent value="login">
            <form onSubmit={handleLogin} className="space-y-4">
              <Input
                type="email"
                placeholder="Váš email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Input
                type="password"
                placeholder="Vaše heslo"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button type="submit" className="w-full" disabled={loading}>
                {loading ? "Prihlasovanie..." : "Prihlásiť sa"}
              </Button>
            </form>
          </TabsContent>
          <TabsContent value="signup">
            <form onSubmit={handleSignUp} className="space-y-4">
              <Input
                type="email"
                placeholder="Váš email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Input
                type="password"
                placeholder="Vaše heslo"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <RadioGroup value={role} onValueChange={setRole}>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="student" id="r-student" />
                  <Label htmlFor="r-student">Študent</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="teacher" id="r-teacher" />
                  <Label htmlFor="r-teacher">Učiteľ</Label>
                </div>
              </RadioGroup>
              <Button type="submit" className="w-full" disabled={loading}>
                {loading ? "Registrácia..." : "Registrovať sa"}
              </Button>
            </form>
          </TabsContent>
        </Tabs>
        {error && (
          <Alert variant="destructive" className="mt-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
}
