// src/components/AssignedTasks.js
import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "./ui/card";
import { toast } from "react-hot-toast";
import MixedContent from "./MixedContent";
import { format } from "date-fns";
import { sk } from "date-fns/locale";
import { CheckCircle, Clock, ArrowRight } from "lucide-react";

const AssignedTasks = ({ userId, onViewProblem, onTaskCompleted, onClose }) => {
  const [assignedTasks, setAssignedTasks] = useState([]);

  useEffect(() => {
    fetchAssignedTasks();
  }, [userId]);

  const fetchAssignedTasks = async () => {
    const { data, error } = await supabase
      .from("assigned_problems")
      .select(
        `
        *,
        math_problems (*)
      `
      )
      .eq("student_id", userId)
      .order("due_date", { ascending: true });

    if (error) {
      console.error("Chyba pri načítaní zadaných úloh:", error);
      toast.error("Nepodarilo sa načítať zadané úlohy");
    } else {
      setAssignedTasks(data);
    }
  };

  const handleCompleteTask = async (taskId) => {
    try {
      const { data, error } = await supabase
        .from("assigned_problems")
        .update({ status: "completed" })
        .eq("id", taskId)
        .select()
        .single();

      if (error) throw error;

      if (data) {
        setAssignedTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.id === taskId ? { ...task, status: "completed" } : task
          )
        );
        toast.success("Úloha bola úspešne dokončená");
        onTaskCompleted(); // Call the callback function to update the count in the parent component
      } else {
        throw new Error("No data returned from update operation");
      }
    } catch (error) {
      console.error("Chyba pri dokončení úlohy:", error);
      toast.error("Nepodarilo sa dokončiť úlohu");
    }
  };

  return (
    <div className="space-y-6 p-6 bg-white rounded-lg shadow-lg max-w-2xl mx-auto">
      <h2 className="text-3xl font-bold text-gray-800">Vaše zadané úlohy</h2>
      {assignedTasks.length === 0 ? (
        <p className="text-gray-600">Nemáte žiadne zadané úlohy.</p>
      ) : (
        <div className="space-y-4">
          {assignedTasks.map((task) => (
            <Card
              key={task.id}
              className="overflow-hidden transition-shadow duration-300 hover:shadow-md"
            >
              <CardHeader className="bg-gray-50">
                <CardTitle className="flex justify-between items-center">
                  <span>
                    Termín:{" "}
                    {format(new Date(task.due_date), "dd. MMMM yyyy", {
                      locale: sk,
                    })}
                  </span>
                  {task.status === "completed" ? (
                    <CheckCircle className="text-green-500" />
                  ) : (
                    <Clock className="text-yellow-500" />
                  )}
                </CardTitle>
              </CardHeader>
              <CardContent className="pt-4">
                <div className="font-medium text-gray-700 mb-2">Otázka:</div>
                <MixedContent content={task.math_problems.question} />
                <p className="mt-2 text-sm text-gray-600">
                  Stav:{" "}
                  {task.status === "completed" ? "Dokončené" : "Nedokončené"}
                </p>
              </CardContent>
              <CardFooter className="bg-gray-50 flex justify-between">
                {task.status !== "completed" ? (
                  <Button
                    onClick={() => handleCompleteTask(task.id)}
                    variant="outline"
                  >
                    Označiť ako dokončené
                  </Button>
                ) : (
                  <span className="text-green-600 font-medium">Dokončené</span>
                )}
                <Button
                  onClick={() => {
                    onViewProblem(task.math_problems.id);
                    onClose();
                  }}
                  variant="link"
                  className="text-blue-600 hover:text-blue-800"
                >
                  Zobraziť príklad <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default AssignedTasks;
