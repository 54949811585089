// src/components/SearchDropdown.js

import React, { useMemo } from "react";
import MixedContent from "./MixedContent";
import { Checkbox } from "./ui/checkbox";
// src/components/SearchDropdown.js

const SearchDropdown = ({
  results,
  onSelect,
  allTags,
  selectedTags = [], // Add default empty array
  onTagToggle,
  availableTagIds,
}) => {
  const groupedResults = useMemo(() => {
    return results.reduce((acc, item) => {
      // Add null check for item.tags
      if (
        item.tags &&
        Array.isArray(item.tags) &&
        // Check if selectedTags exists and has items before using every
        (!selectedTags.length ||
          selectedTags.every((tagId) => item.tags.includes(tagId)))
      ) {
        item.tags.forEach((tagId) => {
          if (!acc[tagId]) {
            acc[tagId] = [];
          }
          acc[tagId].push(item);
        });
      }
      return acc;
    }, {});
  }, [results, selectedTags]);

  // Add null checks for rendered content
  return (
    <div className="absolute z-10 w-full bg-white shadow-lg rounded-b-lg mt-1 border border-gray-200 max-h-96 overflow-y-auto">
      {Object.entries(groupedResults).map(([tagId, items]) => {
        const tag = allTags?.find((t) => t.id === parseInt(tagId));
        if (!tag) return null;

        const isTagSelected = selectedTags?.includes(tag.id);
        const isTagAvailable = availableTagIds?.includes(tag.id);

        if (!isTagSelected && !isTagAvailable) return null;

        return (
          <div key={tagId} className="p-4 border-b border-gray-200">
            <div className="flex items-center space-x-2 mb-2">
              <Checkbox
                id={`tag-${tagId}`}
                checked={isTagSelected}
                onCheckedChange={() => onTagToggle(tag.id)}
              />
              <label
                htmlFor={`tag-${tagId}`}
                className="font-semibold text-lg cursor-pointer"
              >
                {tag.name}
              </label>
            </div>
            <div className="ml-6 space-y-2">
              {items.map((item, index) => (
                <div
                  key={`${item.id || index}`}
                  className="cursor-pointer hover:bg-gray-100 p-2 rounded"
                  onClick={() => onSelect(item)}
                >
                  <div className="font-medium">
                    <MixedContent content={item.question} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SearchDropdown;
