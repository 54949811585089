import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { toast } from "react-hot-toast";

const TestManager = ({ isOpen, onClose, selectedProblems }) => {
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState("");
  const [newTestName, setNewTestName] = useState("");
  const [isCreatingNew, setIsCreatingNew] = useState(false);

  useEffect(() => {
    fetchTests();
  }, []);

  const fetchTests = async () => {
    const { data, error } = await supabase.from("tests").select("*");
    if (error) {
      console.error("Chyba pri načítaní testov:", error);
      toast.error("Nepodarilo sa načítať testy");
    } else {
      setTests(data);
    }
  };

  const handleAddToTest = async () => {
    let testId = selectedTest;

    if (isCreatingNew) {
      const { data, error } = await supabase
        .from("tests")
        .insert({ name: newTestName })
        .select();

      if (error) {
        console.error("Chyba pri vytváraní nového testu:", error);
        toast.error("Nepodarilo sa vytvoriť nový test");
        return;
      }

      testId = data[0].id;
    }

    const problemAssociations = selectedProblems.map((problemId) => ({
      test_id: testId,
      problem_id: problemId,
    }));

    const { error } = await supabase
      .from("test_problems")
      .insert(problemAssociations);

    if (error) {
      console.error("Chyba pri pridávaní príkladov do testu:", error);
      toast.error("Nepodarilo sa pridať príklady do testu");
    } else {
      toast.success("Príklady boli úspešne pridané do testu");
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Pridať Príklady do Testu</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          {!isCreatingNew && (
            <div>
              <Label htmlFor="test-select">Vyberte Test</Label>
              <Select value={selectedTest} onValueChange={setSelectedTest}>
                <SelectTrigger>
                  <SelectValue placeholder="Vyberte test" />
                </SelectTrigger>
                <SelectContent>
                  {tests.map((test) => (
                    <SelectItem key={test.id} value={test.id}>
                      {test.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
          {isCreatingNew && (
            <div>
              <Label htmlFor="new-test-name">Názov Nového Testu</Label>
              <Input
                id="new-test-name"
                value={newTestName}
                onChange={(e) => setNewTestName(e.target.value)}
                placeholder="Zadajte názov nového testu"
              />
            </div>
          )}

          <div className="right-1 z-50 flex space-x-4">
            <Button onClick={() => setIsCreatingNew(!isCreatingNew)}>
              {isCreatingNew ? "Vybrať Existujúci Test" : "Vytvoriť Nový Test"}
            </Button>
            <Button
              onClick={handleAddToTest}
              disabled={!selectedTest && !newTestName}
            >
              Pridať do Testu
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TestManager;
