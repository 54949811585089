// Modify the AdminActionsDropdown.js
import { useState } from "react";
import {
  MoreHorizontal,
  Settings,
  BarChart2,
  Plus,
  Tag,
  Calendar,
  FileText,
  ClipboardList,
  PlusCircle,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Button } from "./ui/button";

export default function AdminActionsDropdown({
  onShowStatistics,
  onAddProblem,
  onManageTags,
  onShowCalendar,
  onManageTests,
  onAssignTask,
  onShowUserStatus,
}) {
  const [open, setOpen] = useState(false);

  const handleAction = (action) => {
    setOpen(false); // Close the dropdown
    action(); // Execute the action
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm">
          <Settings className="h-5 w-5 mr-2" />
          Admin
          <MoreHorizontal className="h-4 w-4 ml-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Správa obsahu</DropdownMenuLabel>
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => handleAction(onAddProblem)}>
            <Plus className="mr-2 h-4 w-4" />
            <span>Pridať príklad</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleAction(onManageTags)}>
            <Tag className="mr-2 h-4 w-4" />
            <span>Spravovať tagy</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleAction(onManageTests)}>
            <FileText className="mr-2 h-4 w-4" />
            <span>Správa testov</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>Študenti</DropdownMenuLabel>
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => handleAction(onShowCalendar)}>
            <Calendar className="mr-2 h-4 w-4" />
            <span>Kalendár lekcií</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleAction(onAssignTask)}>
            <PlusCircle className="mr-2 h-4 w-4" />
            <span>Priradiť úlohu</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleAction(onShowUserStatus)}>
            <ClipboardList className="mr-2 h-4 w-4" />
            <span>Stav úloh používateľov</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => handleAction(onShowStatistics)}>
          <BarChart2 className="mr-2 h-4 w-4" />
          <span>Štatistiky</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
