import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/dialog";
import { PlusCircle, Pencil, Trash2, Save } from "lucide-react";
import { toast } from "react-hot-toast";
import MixedContent from "./MixedContent";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";

const TestEditor = ({ isOpen, onClose }) => {
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState(null);
  const [testName, setTestName] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [testProblems, setTestProblems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchTests();
    }
  }, [isOpen]);

  const fetchTests = async () => {
    setLoading(true);
    try {
      const { data: testsData, error: testsError } = await supabase
        .from("tests")
        .select("*")
        .order("created_at", { ascending: false });

      if (testsError) throw testsError;
      setTests(testsData);
    } catch (error) {
      toast.error("Chyba pri načítaní testov");
      console.error("Error fetching tests:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTestProblems = async (testId) => {
    try {
      const { data, error } = await supabase
        .from("test_problems")
        .select(
          `
          *,
          math_problems (*)
        `
        )
        .eq("test_id", testId);

      if (error) throw error;
      setTestProblems(data);
    } catch (error) {
      toast.error("Chyba pri načítaní príkladov testu");
      console.error("Error fetching test problems:", error);
    }
  };

  const handleSelectTest = (test) => {
    setSelectedTest(test);
    setTestName(test.name);
    fetchTestProblems(test.id);
  };

  const handleCreateTest = async () => {
    try {
      const { data, error } = await supabase
        .from("tests")
        .insert([{ name: testName }])
        .select()
        .single();

      if (error) throw error;

      setTests([data, ...tests]);
      setTestName("");
      toast.success("Test bol úspešne vytvorený");
    } catch (error) {
      toast.error("Chyba pri vytváraní testu");
      console.error("Error creating test:", error);
    }
  };

  const handleUpdateTest = async () => {
    try {
      const { error } = await supabase
        .from("tests")
        .update({ name: testName })
        .eq("id", selectedTest.id);

      if (error) throw error;

      setTests(
        tests.map((test) =>
          test.id === selectedTest.id ? { ...test, name: testName } : test
        )
      );
      setEditMode(false);
      toast.success("Test bol úspešne upravený");
    } catch (error) {
      toast.error("Chyba pri úprave testu");
      console.error("Error updating test:", error);
    }
  };

  const handleDeleteTest = async (testId) => {
    try {
      // First delete all test problems
      const { error: problemsError } = await supabase
        .from("test_problems")
        .delete()
        .eq("test_id", testId);

      if (problemsError) throw problemsError;

      // Then delete the test
      const { error: testError } = await supabase
        .from("tests")
        .delete()
        .eq("id", testId);

      if (testError) throw testError;

      setTests(tests.filter((test) => test.id !== testId));
      setSelectedTest(null);
      setTestProblems([]);
      toast.success("Test bol úspešne vymazaný");
    } catch (error) {
      toast.error("Chyba pri mazaní testu");
      console.error("Error deleting test:", error);
    }
  };

  const handleRemoveProblem = async (problemId) => {
    try {
      const { error } = await supabase
        .from("test_problems")
        .delete()
        .eq("test_id", selectedTest.id)
        .eq("problem_id", problemId);

      if (error) throw error;

      setTestProblems(testProblems.filter((tp) => tp.problem_id !== problemId));
      toast.success("Príklad bol odstránený z testu");
    } catch (error) {
      toast.error("Chyba pri odstraňovaní príkladu");
      console.error("Error removing problem:", error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Správa Testov</DialogTitle>
        </DialogHeader>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Card>
            <CardHeader>
              <CardTitle>Zoznam testov</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex space-x-2 mb-4">
                <Input
                  placeholder="Názov nového testu"
                  value={testName}
                  onChange={(e) => setTestName(e.target.value)}
                />
                {editMode ? (
                  <Button onClick={handleUpdateTest}>
                    <Save className="h-4 w-4 mr-2" />
                    Uložiť
                  </Button>
                ) : (
                  <Button onClick={handleCreateTest}>
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Pridať
                  </Button>
                )}
              </div>

              <div className="space-y-2">
                {tests.map((test) => (
                  <div
                    key={test.id}
                    className={`flex justify-between items-center p-2 rounded-lg ${
                      selectedTest?.id === test.id
                        ? "bg-blue-50"
                        : "hover:bg-gray-50"
                    }`}
                  >
                    <span
                      className="cursor-pointer flex-grow"
                      onClick={() => handleSelectTest(test)}
                    >
                      {test.name}
                    </span>
                    <div className="flex space-x-2">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          setEditMode(true);
                          handleSelectTest(test);
                        }}
                      >
                        <Pencil className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => handleDeleteTest(test.id)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          {selectedTest && (
            <Card>
              <CardHeader>
                <CardTitle>Príklady v teste: {selectedTest.name}</CardTitle>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Príklad</TableHead>
                      <TableHead className="w-[100px]">Akcie</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {testProblems.map((tp) => (
                      <TableRow key={tp.id}>
                        <TableCell>
                          <MixedContent content={tp.math_problems.question} />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() =>
                              handleRemoveProblem(tp.math_problems.id)
                            }
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TestEditor;
