import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Checkbox } from "./ui/checkbox";
import {
  Search,
  ChevronDown,
  ChevronUp,
  Tag,
  SortAsc,
  X,
  SortDesc,
  PlusCircle,
  MinusCircle,
} from "lucide-react";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Badge } from "./ui/badge";
import SearchableTagSelect from "./SearchableTagSelect";
import SearchDropdown from "./SearchDropdown";

const ImprovedFiltering = ({
  searchTerm,
  setSearchTerm,
  allSelected,
  onSelectAll,
  selectedDifficulty,
  setSelectedDifficulty,
  sortBy,
  setSortBy,
  sortOrder,
  toggleSortOrder,
  showSavedOnly,
  setShowSavedOnly,
  difficultyOptions,
  allTags,
  sortOptions,
  canSaveProblems,
  onSearch,
  searchResults,
  onProblemSelect,
  mathProblems,
  setFilteredProblems,
  savedProblems,
  updateFilterCriteria,
  onProblemClick,
  isAdminUser,
}) => {
  const [showTagSelect, setShowTagSelect] = useState(false);
  const [includedTags, setIncludedTags] = useState([]);
  const [excludedTags, setExcludedTags] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const searchRef = useRef(null);

  const updateAvailableTags = useCallback(() => {
    if (includedTags.length === 0 && excludedTags.length === 0) {
      return allTags.map((tag) => tag.id);
    }

    const relatedTagIds = new Set();
    mathProblems.forEach((problem) => {
      if (
        problem.tags &&
        includedTags.every((tagId) => problem.tags.includes(tagId)) &&
        !excludedTags.some((tagId) => problem.tags.includes(tagId))
      ) {
        problem.tags.forEach((tagId) => {
          relatedTagIds.add(tagId);
        });
      }
    });

    return Array.from(relatedTagIds);
  }, [includedTags, excludedTags, mathProblems, allTags]);

  const availableTagIds = useMemo(
    () => updateAvailableTags(),
    [updateAvailableTags]
  );

  const filterProblems = useCallback(
    (problems) => {
      return problems.filter((problem) => {
        const matchesSearch =
          !searchTerm ||
          problem.question.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesDifficulty =
          selectedDifficulty === "všetky" ||
          problem.difficulty === selectedDifficulty;
        const matchesIncludedTags =
          includedTags.length === 0 ||
          includedTags.every((tagId) => problem.tags.includes(tagId));
        const matchesExcludedTags =
          excludedTags.length === 0 ||
          !excludedTags.some((tagId) => problem.tags.includes(tagId));
        const matchesSaved =
          !showSavedOnly || savedProblems.includes(problem.id);

        return (
          matchesSearch &&
          matchesDifficulty &&
          matchesIncludedTags &&
          matchesExcludedTags &&
          matchesSaved
        );
      });
    },
    [
      searchTerm,
      selectedDifficulty,
      includedTags,
      excludedTags,
      showSavedOnly,
      savedProblems,
    ]
  );

  const sortProblems = useCallback(
    (problems, currentSortBy, currentSortOrder) => {
      return [...problems].sort((a, b) => {
        if (currentSortBy === "newest") {
          return currentSortOrder === "asc" ? a.id - b.id : b.id - a.id;
        }
        if (a[currentSortBy] < b[currentSortBy])
          return currentSortOrder === "asc" ? -1 : 1;
        if (a[currentSortBy] > b[currentSortBy])
          return currentSortOrder === "asc" ? 1 : -1;
        return 0;
      });
    },
    []
  );

  const filteredAndSortedProblems = useMemo(() => {
    const filtered = filterProblems(mathProblems);
    return sortProblems(filtered, sortBy, sortOrder);
  }, [mathProblems, filterProblems, sortProblems, sortBy, sortOrder]);

  useEffect(() => {
    setFilteredProblems(filteredAndSortedProblems);
  }, [filteredAndSortedProblems, setFilteredProblems]);

  const handleClearSearch = useCallback(() => {
    setSearchTerm("");
    onSearch("");
    setShowDropdown(false);
  }, [setSearchTerm, onSearch]);

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchTerm(value);
      onSearch(value);
      setShowDropdown(value.length > 0);
    },
    [setSearchTerm, onSearch]
  );

  const handleSelectSearchResult = useCallback(
    (item) => {
      setSearchTerm("");
      setShowDropdown(false);
      onProblemClick(item);
    },
    [setSearchTerm, onProblemClick]
  );

  const handleTagToggle = useCallback(
    (tagId, action) => {
      console.log("Tag toggle:", tagId, action); // Debug log

      if (action === "include") {
        setIncludedTags((prev) => {
          const updatedTags = prev.includes(tagId)
            ? prev.filter((id) => id !== tagId)
            : [...prev, tagId];
          console.log("Updated included tags:", updatedTags); // Debug log
          updateFilterCriteria({
            includedTags: updatedTags,
            selectedTags: updatedTags
              .map((id) => allTags.find((tag) => tag.id === id))
              .filter(Boolean),
          });
          return updatedTags;
        });
        setExcludedTags((prev) => prev.filter((id) => id !== tagId));
      } else if (action === "exclude") {
        setExcludedTags((prev) => {
          const updatedTags = prev.includes(tagId)
            ? prev.filter((id) => id !== tagId)
            : [...prev, tagId];
          console.log("Updated excluded tags:", updatedTags); // Debug log
          updateFilterCriteria({ excludedTags: updatedTags });
          return updatedTags;
        });
        setIncludedTags((prev) => prev.filter((id) => id !== tagId));
      }
    },
    [updateFilterCriteria, allTags]
  );

  const handleSortChange = useCallback(
    (value) => {
      setSortBy(value);
      updateFilterCriteria({ sortBy: value });
    },
    [setSortBy, updateFilterCriteria]
  );

  const handleSortOrderToggle = useCallback(() => {
    const newOrder = sortOrder === "asc" ? "desc" : "asc";
    toggleSortOrder();
    updateFilterCriteria({ sortOrder: newOrder });
  }, [sortOrder, toggleSortOrder, updateFilterCriteria]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-white shadow-md rounded-lg p-4 space-y-4">
      <div className="relative" ref={searchRef}>
        <Input
          type="text"
          placeholder="Hľadať príklady"
          value={searchTerm}
          onChange={handleSearchChange}
          className="pl-10 pr-10 py-2 w-full"
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
        {searchTerm && (
          <button
            onClick={handleClearSearch}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
          >
            <X className="h-5 w-5" />
          </button>
        )}
        {showDropdown && (
          <SearchDropdown
            results={searchResults}
            onSelect={handleSelectSearchResult}
            allTags={allTags}
            selectedTags={includedTags} // Make sure this is always an array
            excludedTags={excludedTags} // Make sure this is always an array
            onTagToggle={handleTagToggle}
            availableTagIds={availableTagIds}
          />
        )}
      </div>
      <div className="flex flex-wrap gap-2 items-center">
        <Select
          value={selectedDifficulty}
          onValueChange={(value) => {
            setSelectedDifficulty(value);
            updateFilterCriteria({ selectedDifficulty: value });
          }}
        >
          <SelectTrigger className="w-[150px]">
            <SelectValue placeholder="Obtiažnosť" />
          </SelectTrigger>
          <SelectContent>
            {difficultyOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Select value={sortBy} onValueChange={handleSortChange}>
          <SelectTrigger className="w-[150px]">
            <SelectValue placeholder="Zoradiť podľa" />
          </SelectTrigger>
          <SelectContent>
            {sortOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Button variant="outline" size="icon" onClick={handleSortOrderToggle}>
          {sortOrder === "asc" ? (
            <SortAsc className="h-4 w-4" />
          ) : (
            <SortDesc className="h-4 w-4" />
          )}
        </Button>

        {canSaveProblems && (
          <div className="flex items-center space-x-2">
            <Checkbox
              id="showSavedOnly"
              checked={showSavedOnly}
              onCheckedChange={(checked) => {
                setShowSavedOnly(checked);
                updateFilterCriteria({ showSavedOnly: checked });
              }}
            />
            <Label htmlFor="showSavedOnly" className="text-sm">
              Len uložené
            </Label>
          </div>
        )}
      </div>
      <div>
        <Button
          variant="outline"
          onClick={() => setShowTagSelect(!showTagSelect)}
          className="w-full justify-between"
        >
          <span className="flex items-center">
            <Tag className="mr-2 h-4 w-4" />
            Tagy ({includedTags.length + excludedTags.length} vybrané)
          </span>
          {showTagSelect ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </Button>
        {showTagSelect && (
          <div className="mt-2">
            <SearchableTagSelect
              allTags={allTags.filter((tag) =>
                availableTagIds.includes(tag.id)
              )}
              includedTags={includedTags}
              excludedTags={excludedTags}
              onTagToggle={handleTagToggle}
            />
          </div>
        )}
      </div>

      {isAdminUser && (
        <div className="flex items-center space-x-2">
          <Checkbox
            id="selectAll"
            checked={allSelected}
            onCheckedChange={onSelectAll}
          />
          <Label htmlFor="selectAll" className="text-sm">
            Vybrať všetky príklady
          </Label>
        </div>
      )}

      {(includedTags.length > 0 || excludedTags.length > 0) && (
        <div className="flex flex-wrap gap-2">
          {includedTags.map((tagId) => {
            const tag = allTags.find((t) => t.id === tagId);
            return (
              <Badge key={tagId} variant="secondary" className="text-sm">
                <PlusCircle className="mr-1 h-3 w-3" />
                {tag ? tag.name : "Unknown Tag"}
                <button
                  onClick={() => handleTagToggle(tagId, "include")}
                  className="ml-1 text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </Badge>
            );
          })}
          {excludedTags.map((tagId) => {
            const tag = allTags.find((t) => t.id === tagId);
            return (
              <Badge
                key={tagId}
                variant="secondary"
                className="text-sm bg-red-100"
              >
                <MinusCircle className="mr-1 h-3 w-3" />
                {tag ? tag.name : "Unknown Tag"}
                <button
                  onClick={() => handleTagToggle(tagId, "exclude")}
                  className="ml-1 text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </Badge>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ImprovedFiltering;
